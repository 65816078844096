import React, { useState } from "react";
import "./Upload.css";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import {
  addDoc,
  collection,
  doc,
  serverTimestamp,
  setDoc,
} from "firebase/firestore";
import { auth, db } from "../../firebase";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { selectAuth, selectUserDetails, setLoginDialog } from "../../store";

export default function Upload() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userDetails = useSelector(selectUserDetails);
  const authed = useSelector(selectAuth);
  const [message, setMessage] = useState("");
  const [file, setFile] = useState(null);
  const [progress, setProgress] = useState(0);
  const [uploadingDialogOpen, setUploadingDialogOpen] = useState(false);

  const handleFileChange = (e) => {
    if (e.target.files[0]) {
      setFile(e.target.files[0]);
    }
  };

  const handleUpload = async () => {
    if (authed) {
      setUploadingDialogOpen(true);
      if (
        file &&
        userDetails?.name &&
        userDetails?.email &&
        userDetails?.phone
      ) {
        const storage = getStorage();
        const storageRef = ref(storage, `uploads/${file.name}`);
        const uploadTask = uploadBytesResumable(storageRef, file);

        uploadTask.on("state_changed", (snapshot) => {
          const uploadProgress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setProgress(uploadProgress);
        });

        try {
          await uploadTask;
          const downloadURL = await getDownloadURL(storageRef);
          console.log("File available at", downloadURL);
          addDoc(collection(db, "UploadPrescription"), {
            fileUrl: downloadURL,
            name: userDetails?.name,
            email: userDetails?.email,
            phoneNumber: userDetails?.phone,
            message: message,
            createdAt: serverTimestamp(),
          });
          setUploadingDialogOpen(false);
          navigate("/");
        } catch (error) {
          console.error("Error uploading file:", error.message);
          setUploadingDialogOpen(false);
        }
      } else {
        if (file) {
          alert("Please fill all required fields!");
        } else {
          alert("No file selected");
        }
        setUploadingDialogOpen(false);
      }
    } else {
      dispatch(setLoginDialog(true));
    }
  };

  return (
    <div className="upload">
      {/* <div className="heading">
        <h4>Upload Prescription</h4>
      </div> */}
      <h4 className="subheading">Send us your prescription</h4>
      <p className="content">
        Not sure which tests to take? Share your prescription with us and our
        team will call you to book tests for you
      </p>
      <div className="upload_container">
        <label for="name" className="form-label">
          Message (Optional)
          <input value={message} onChange={(e) => setMessage(e.target.value)} />
        </label>

        <label htmlFor="avatar" className="form-label label">
          Choose Prescription
          <input
            type="file"
            id="avatar"
            name="avatar"
            accept="image/png, image/jpeg, application/pdf"
            onChange={handleFileChange}
          />
        </label>
        {/* <input type="checkbox" id="scales" name="scales" />
            <label for="scales" className="check">
              I agree that my submitted data is being collected and stored.
            </label> */}
        <p className="label1">
          You can upload a docuument in formats: PDF,JPG,JPEG,PNG
        </p>
        <button className="upload_button" onClick={handleUpload}>
          Upload Prescription
        </button>
      </div>
      <Dialog
        open={uploadingDialogOpen}
        onClose={() => setUploadingDialogOpen(false)}
      >
        {progress > 0 && <progress value={progress} max="100" />}
        <p>Uploading...</p>
      </Dialog>
    </div>
  );
}
