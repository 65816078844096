import React, { useState } from "react";
import "./Account.css";
import { EditOutlined } from "@mui/icons-material";
import { Dialog } from "@mui/material";
import { doc, updateDoc } from "firebase/firestore";
import { auth, db } from "../../firebase";
import { selectUserDetails, setUserDetails } from "../../store";
import { useDispatch, useSelector } from "react-redux";

function AddressItem({ item }) {
  const [saveAs, setSaveAs] = useState(item.label);
  const [address, setAddress] = useState(item.address);
  const [area, setArea] = useState(item.area);
  const [city, setCity] = useState(item.city);
  const [pincode, setPincode] = useState(item.pincode);
  const [state, setState] = useState(item.state);
  const [addAddressOpen, setAddAddressOpen] = useState(false);
  const dispatch = useDispatch();
  const userDetails = useSelector(selectUserDetails);

  async function handleAddAddress() {
    const userDataCache = localStorage.getItem("userDetails");
    const userData = {
      addresses: [
        ...JSON.parse(userDataCache).addresses?.filter(
          (data) => data.address !== item.address
        ),
        {
          address: address,
          area: area,
          city: city,
          pincode: pincode,
          state: state,
          label: saveAs,
        },
      ],
    };
    updateDoc(doc(db, "Users", userDetails.phone), userData);
    localStorage.setItem(
      "userDetails",
      JSON.stringify({ ...JSON.parse(userDataCache), ...userData })
    );
    dispatch(setUserDetails({ ...JSON.parse(userDataCache), ...userData }));
    setAddAddressOpen(false);
    setArea("");
    setAddress("");
    setPincode("");
    setSaveAs("");
    setCity("");
    setState("");
  }

  return (
    <div className="address_item">
      <h2>{item.label}</h2>
      <p>{item.flatNo + ", " + item.area}</p>
      <p>{item.address}</p>
      {/* <button onClick={() => setAddAddressOpen(true)} className="edit_icon">
        <EditOutlined />
      </button> */}
      <Dialog open={addAddressOpen} onClose={() => setAddAddressOpen(false)}>
        <div className="addresses_div_dialog">
          <h2>Enter Address Details</h2>
          <label>
            Save As
            <div className="addresses_div_dialog_saveas">
              {["Home", "Parent's", "Spouse", "Others"].map((item, index) => {
                return (
                  <button
                    onClick={() => setSaveAs(item)}
                    className={saveAs === item ? "active" : ""}
                    key={index}
                  >
                    {item}
                  </button>
                );
              })}
            </div>
          </label>
          <label>
            Address
            <input
              type="text"
              placeholder="Address"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
            />
          </label>
          <div className="profile_input_group">
            <label>
              Area
              <input
                type="text"
                placeholder="Area"
                value={area}
                onChange={(e) => setArea(e.target.value)}
              />
            </label>
            <label>
              City
              <input
                type="text"
                placeholder="City"
                value={city}
                readOnly
                onChange={(e) => setCity(e.target.value)}
              />
            </label>
          </div>
          <div className="profile_input_group">
            <label>
              Pincode
              <input
                type="text"
                placeholder="Pincode"
                value={pincode}
                readOnly
                onChange={(e) => setPincode(e.target.value)}
              />
            </label>
            <label>
              State
              <input
                type="text"
                placeholder="State"
                value={state}
                readOnly
                onChange={(e) => setState(e.target.value)}
              />
            </label>
          </div>
          <button className="account_save_btn" onClick={handleAddAddress}>
            Save Address
          </button>
        </div>
      </Dialog>
    </div>
  );
}

export default AddressItem;
