import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../assets/logo.png";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import UploadIcon from "@mui/icons-material/Upload";
import Badge from "@mui/material/Badge";
import { Search } from "../search/Search";
import "./Header.css";
import {
  selectAuth,
  selectCart,
  selectUserDetails,
  setLoginDialog,
} from "../../store";
import EventIcon from "@mui/icons-material/Event";
import { CloseOutlined, PersonOutline } from "@mui/icons-material";
import SearchIcon from "@mui/icons-material/Search";
import PersonIcon from "@mui/icons-material/Person";
import Login from "../../pages/Login/Login";
import Register from "../../pages/Register/Register";
import { useDispatch, useSelector } from "react-redux";

export default function Header() {
  const cartno = useSelector(selectCart);
  const navigate = useNavigate();
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [loginOpen, setLoginOpen] = useState(false);
  const [registerOpen, setRegisterOpen] = useState(false);
  const [isSidebarOpen, setSidebarOpen] = useState(false); // State to control the sidebar
  const authed = useSelector(selectAuth);
  const userDetails = useSelector(selectUserDetails);
  const dispatch = useDispatch();

  const toggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  useEffect(() => {
    if (authed) {
      if (
        userDetails?.name === "" ||
        userDetails?.name === undefined ||
        userDetails?.name === null
      ) {
        setRegisterOpen(true);
      }
    }
  }, [authed]);

  return (
    <div className="header">
      <div className="menu-button" onClick={toggleSidebar}>
        <div className="bar"></div>
        <div className="bar"></div>
        <div className="bar"></div>
      </div>
      <Link to={"/"}>
        <img className="logo-container" src={logo} alt="logo"></img>
      </Link>
      {/* <button className="upload-button" onClick={() => navigate("/upload")}>
        <UploadIcon /> Upload <br />
        Prescription
      </button> */}
      {/* <Search /> */}
      <div className="nav-buttons">
        <button className="button" onClick={() => navigate("/advantage-plan")}>
          SRI CHAKRA ADVANTAGE PLAN
        </button>
        {/* <button className="download-button">
          <DownloadIcon /> Download
          <br />
          Reports
        </button> */}
      </div>
      {/* <button onClick={() => navigate("/appoint")} className="download-button">
        <EventIcon /> Book An
        <br />
        Appointment
      </button> */}
      <div className="cart-icons">
        <button className="cart-button" onClick={() => navigate("/cart")}>
          <p>Cart</p>
          <Badge badgeContent={cartno.length} color="error">
            <ShoppingCartOutlinedIcon />
          </Badge>
        </button>
        <button
          className="cart-button"
          // onClick={() => navigate(authed === true ? "/account" : "/login")}
          onClick={() =>
            authed === true
              ? navigate("/account")
              : dispatch(setLoginDialog(true))
          }
        >
          <p>{authed === true ? "Profile" : "Login"}</p>
          <PersonOutline />
        </button>
      </div>
      <div className={`sidebar ${isSidebarOpen ? "open" : ""}`}>
        <ul className="sidebar_nav">
          <button className="close_btn" onClick={toggleSidebar}>
            <CloseOutlined color="#fff" />
          </button>

          <Link
            onClick={toggleSidebar}
            to={"/"}
            className="nav-item-header active"
          >
            <li className="nav-link-header">Home</li>
          </Link>
          <Link
            onClick={toggleSidebar}
            to={"http://srichakrahospitals.com/aboutus.html"}
            className="nav-item-header"
          >
            <li className="nav-link-header">About Us</li>
          </Link>
          <Link
            onClick={toggleSidebar}
            to={"/tests"}
            className="nav-item-header"
          >
            <li className="nav-link-header">Tests/Packages</li>
          </Link>
          <Link onClick={toggleSidebar} to={"/why"} className="nav-item-header">
            <li className="nav-link-header">Why Sri Chakra</li>
          </Link>
          <Link
            onClick={toggleSidebar}
            to={"/appoint"}
            className="nav-item-header"
          >
            <li className="nav-link-header">Book An Appointment</li>
          </Link>
          <Link
            onClick={toggleSidebar}
            to={"/advantage-plan"}
            className="nav-item-header"
          >
            <li className="nav-link-header">Sri Chakra Advantage Plan</li>
          </Link>
          <Link
            onClick={toggleSidebar}
            to={"/upload"}
            className="nav-item-header"
          >
            <li className="nav-link-header">Upload Prescription</li>
          </Link>
        </ul>
      </div>
      <Login
        loginOpen={loginOpen}
        setLoginOpen={setLoginOpen}
        setRegisterOpen={setRegisterOpen}
      />
      <Register registerOpen={registerOpen} setRegisterOpen={setRegisterOpen} />
    </div>
  );
}
