import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./Plancard.css";
import { useNavigate } from "react-router-dom";
import { selectCart, setCart, setUserDetails } from "../../store";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { CloseRounded } from "@mui/icons-material";
import FullDetails from "../FullDetails/FullDetails";

export default function Plancard({ item }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const cart = useSelector(selectCart);
  const [fullDetailsOpen, setFullDetailsOpen] = useState(false);

  return (
    <div className="card_main">
      <div
        onClick={() => setFullDetailsOpen(true)}
        className="card_main_details"
      >
        <h2 className="title">{item.name}</h2>
        <p>
          <span>
            {item.parameters.length === 0 ? 1 : item.parameters.length}
          </span>{" "}
          {item.parameters.length === 0 ? "Test" : "Tests"} | Reports in{" "}
          <span>{item.reportsIn}</span>
        </p>
        <p className="description">{item.code}</p>
      </div>
      <div className="buttons_div">
        <div className="price_div">
          <h3 className="price">{"₹" + item.price}</h3>
          <p className="mrp">{"₹" + item.mrp}</p>
        </div>
        <button
          className="plan_btn"
          style={{
            backgroundColor:
              cart?.filter((data) => data.id === item.id).length === 0
                ? "#002e81"
                : "teal",
            borderColor:
              cart?.filter((data) => data.id === item.id).length === 0
                ? "#002e81"
                : "teal",
          }}
          onClick={() => {
            if (cart?.filter((data) => data.id === item.id).length === 0) {
              localStorage.setItem(
                "cart",
                JSON.stringify([
                  ...cart,
                  {
                    id: item.id,
                    active: item.active,
                    code: item.code,
                    type: item.type,
                    name: item.name,
                    mrp: item.mrp,
                    price: item.price,
                    deptname: item.deptname,
                    subdeptname: item.subdeptname,
                    gender: item.gender,
                    visitType: item.visitType,
                    fasting: item.fasting,
                    reportsIn: item.reportsIn,
                    description: item.description,
                    parameters: item.parameters,
                    createdAt: item.createdAt,
                    createdAtOriginal: item.createdAtOriginal,
                    testInstructions: item.testInstructions,
                    imgUrl: item.imgUrl,
                  },
                ])
              );
              dispatch(
                setCart([
                  ...cart,
                  {
                    id: item.id,
                    active: item.active,
                    code: item.code,
                    type: item.type,
                    name: item.name,
                    mrp: item.mrp,
                    price: item.price,
                    deptname: item.deptname,
                    subdeptname: item.subdeptname,
                    gender: item.gender,
                    visitType: item.visitType,
                    fasting: item.fasting,
                    reportsIn: item.reportsIn,
                    description: item.description,
                    parameters: item.parameters,
                    createdAt: item.createdAt,
                    createdAtOriginal: item.createdAtOriginal,
                    testInstructions: item.testInstructions,
                    imgUrl: item.imgUrl,
                  },
                ])
              );
            } else {
              localStorage.setItem(
                "cart",
                JSON.stringify(cart.filter((data) => data.id !== item.id))
              );
              dispatch(setCart(cart.filter((data) => data.id !== item.id)));
            }
          }}
        >
          {/* <ShoppingCartOutlinedIcon /> */}
          {cart?.filter((data) => data.id === item.id).length === 0
            ? "Add"
            : "Remove"}
        </button>
        {/* <button className="plan_btn2" onClick={() => navigate("/details")}>
          Learn More
        </button> */}
      </div>
      <FullDetails
        fullDetailsOpen={fullDetailsOpen}
        setFullDetailsOpen={setFullDetailsOpen}
        data={item}
      />
      {/* <Dialog open={fullDetailsOpen} onClose={() => setFullDetailsOpen(false)}>
        <button
          className="tests_container_close_btn"
          onClick={() => setFullDetailsOpen(false)}
        >
          <CloseRounded />
        </button>
        <div className="test_details_container">
          <h2>{item.name}</h2>
          <p>Code: {item.code}</p>
          <p>Type: {item.type}</p>
          <h4>Price: ₹{item.price}</h4>
          <div className="parameters_container">
            <h3>Parameters:</h3>
            {item.parameters?.length === 0 ? (
              <ul>
                <li>{item.name}</li>
              </ul>
            ) : (
              <ul>
                {item.parameters.map((param, index) => (
                  <li key={index}>{param}</li>
                ))}
              </ul>
            )}
          </div>
          <div>
            <h3>Instructions:</h3>
            <p>{item.testInstructions}</p>
          </div>
        </div>
      </Dialog> */}
    </div>
  );
}
