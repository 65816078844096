import {
  Add,
  CheckBox,
  CheckBoxOutlineBlank,
  Close,
  WrongLocation,
} from "@mui/icons-material";
import React from "react";
import "./PatientCartSelection.css";

const PatientCartSelection = ({
  patients,
  onItemSelection,
  selectedPatientsCart,
  removePatient,
  addPatient,
}) => {
  return (
    <div>
      {patients?.map((patient, patientIndex) => (
        <div
          key={patientIndex}
          className="patient-container"
          style={{
            borderColor:
              selectedPatientsCart.filter(
                (data) => data.patientId === patient.name
              ).length !== 0
                ? "#01A766"
                : "#eee",
          }}
        >
          <div
            className="patient-name"
            style={{
              borderBottomWidth: 1,
              backgroundColor: "#fff",
              borderBottomColor:
                selectedPatientsCart.filter(
                  (data) => data.patientId === patient.name
                ).length !== 0
                  ? "#01A766"
                  : "#fff",
            }}
          >
            <span
              style={{
                color:
                  selectedPatientsCart.filter(
                    (data) => data.patientId === patient.name
                  ).length !== 0
                    ? "#01A766"
                    : "#000",
                fontWeight: "600",
              }}
            >
              {patient.name}
            </span>
            <div style={{ display: "flex", alignItems: "center" }}>
              <span
                style={{
                  color: "#000",
                  fontWeight: "600",
                }}
              >
                {patient.gender}, {patient.age}
              </span>
              <div
                onClick={() => {
                  if (
                    selectedPatientsCart.filter(
                      (data) => data.patientId === patient.name
                    ).length !== 0
                  ) {
                    removePatient(patient.name);
                  } else {
                    addPatient(patient.name);
                  }
                }}
                className="icon-container"
              >
                {selectedPatientsCart.filter(
                  (data) => data.patientId === patient.name
                ).length !== 0 ? (
                  <Close />
                ) : (
                  <Add />
                )}
              </div>
            </div>
          </div>
          {selectedPatientsCart.filter(
            (data) => data.patientId === patient.name
          ).length !== 0 && (
            <ul className="cart-items-list">
              {selectedPatientsCart
                .filter((data) => data.patientId === patient.name)[0]
                .cartItems.map((item, index) => (
                  <li
                    key={index}
                    className="item-container"
                    style={{
                      borderTopWidth: index === 0 ? 0 : 1,
                      borderTopColor: "#eee",
                    }}
                    onClick={() => onItemSelection(patient.name, item.id)}
                  >
                    <span className="item-name">{item.name}</span>
                    <div className="item-details">
                      <span className="item-price">₹{item.price}</span>
                      {item.selected ? <CheckBox /> : <CheckBoxOutlineBlank />}
                    </div>
                  </li>
                ))}
            </ul>
          )}
        </div>
      ))}
    </div>
  );
};

export default PatientCartSelection;
