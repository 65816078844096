import { useState } from "react";
import "./Search.css";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate } from "react-router-dom";
// import Button from '@mui/material/Button';

export function Search() {
  const [searchTxt, setsearchTxt] = useState("");
  const navigate = useNavigate();

  return (
    <div className="searchInputs">
      <SearchIcon />
      <input
        value={searchTxt}
        onChange={(text) => setsearchTxt(text.target.value)}
        type="text"
        placeholder="Search test, packages..."
        onKeyDown={(e) => {
          if (e.code === "Enter" || e.code === 13) {
            navigate("/tests", { state: { value: searchTxt } });
          }
        }}
      />
    </div>
  );
}
export default Search;
