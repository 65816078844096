import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./Home.css";
import BannerImg from "../../assets/home_banner.png";
import BannerImg2 from "../../assets/home_banner2.png";
import BannerImgMobile from "../../assets/1.png";
import BannerImgMobile2 from "../../assets/2.png";
import Contact from "../contact/Contact";
import Footer from "../footer/Footer";
import Tests from "../test/Tests";
import Search from "../search/Search";
import UploadIcon from "@mui/icons-material/Upload";
import { auth, db } from "../../firebase";
import {
  doc,
  setDoc,
  collection,
  addDoc,
  serverTimestamp,
} from "firebase/firestore";
import { useDispatch, useSelector } from "react-redux";
import { selectTests } from "../../store";
import Plancard from "../plan/Plancard";
import WhyChooseUs from "../WhyChooseUs/WhyChooseUs";
import {
  CalendarMonth,
  CalendarMonthTwoTone,
  UploadFileOutlined,
} from "@mui/icons-material";

// import { StaticCard } from './StaticCard'
function Home() {
  const navigate = useNavigate();
  const testData = useSelector(selectTests);
  const [bannerImageChange, setbannerImageChange] = useState(
    window.innerWidth < 900 ? BannerImgMobile : BannerImg
  );

  // useEffect(() => {
  //   addDoc(collection(db, "TestsPackages"), {
  //     code: "Lipid1",
  //     type: "Test",
  //     name: "Lipid Profile Test",
  //     mrp: 7000,
  //     price: 399,
  //     deptname: "null",
  //     subdeptname: "DEPARTMENT OF CLINICAL BIOCHEMISTRY",
  //     gender: "Both",
  //     visitType: "Home",
  //     fasting: "Not Required",
  //     reportsIn: "12 hrs",
  //     description:
  //       "It measures cholesterol & triglycerides in the blood & screens your risk for cardiovascular disease. Also known as: Lipid Profile, Lipid Panel, Lipid Screen, Lipid Analysis",
  //     parameters: [
  //       "Cholesterol-Total, Serum",
  //       "CHOL/HDL RATIO",
  //       "HDL Cholesterol Direct",
  //       "HDL / LDL Cholesterol Ratio",
  //       "LDL Cholesterol - Calculated",
  //       "LDL/HDL RATIO",
  //       "Non - HDL Cholesterol, Serum",
  //       "Triglycerides, Serum",
  //       "VLDL",
  //     ],
  //     createdAt: serverTimestamp(),
  //     createdAtOriginal: serverTimestamp(),
  //     testInstructions: ["Age Group: 5+ Years"],
  //     imgUrl: "",
  //   })
  //     .then(() => alert("Su"))
  //     .catch((e) => alert(e));
  // }, []);

  useEffect(() => {
    setTimeout(() => {
      if (window.innerWidth < 900) {
        setbannerImageChange(
          bannerImageChange === BannerImgMobile
            ? BannerImgMobile2
            : BannerImgMobile
        );
      } else {
        setbannerImageChange(
          bannerImageChange === BannerImg ? BannerImg2 : BannerImg
        );
      }
    }, 5000);
  }, [bannerImageChange]);

  return (
    <div style={{ overflow: "hidden" }}>
      <div className="home_banner">
        <img className="home_banner_img" src={bannerImageChange} />
        <div className="home_banner_row">
          <h1>Looking for a test?</h1>
          <Search />
          <div className="home_btns">
            <button
              className="upload-button"
              onClick={() => navigate("/upload")}
            >
              <UploadFileOutlined />
              <p>Upload Prescription</p>
            </button>
            <button
              className="upload-button"
              onClick={() => navigate("/book-appointment")}
            >
              <CalendarMonthTwoTone />
              <p>Book Appointment</p>
            </button>
          </div>
        </div>
      </div>
      <div className="home_banner_row_mobile">
        <h1>Looking for a test?</h1>
        <Search />
        <div className="home_btns">
          <button className="upload-button" onClick={() => navigate("/upload")}>
            <UploadFileOutlined />
            <p>Upload Prescription</p>
          </button>
          <button
            className="upload-button"
            onClick={() => navigate("/book-appointment")}
          >
            <CalendarMonthTwoTone />
            <p>Book Appointment</p>
          </button>
        </div>
      </div>
      <div className="frequently_booked">
        <h1 className="frequently_booked_h1">Frequently Booked</h1>
        <div className="top_packages_list">
          {[
            "Full Body Checkup",
            "Diabetes",
            "Thyroid",
            "Heart",
            "Fever",
            "Vitamin",
            "Arthritis",
            "Cancer",
            "Hormone Tests",
            "Allergy",
            "STD",
            "Antenatal",
            "Fertility",
            "Anemia",
          ].map((item, index) => {
            return (
              <div
                key={index}
                onClick={() => navigate("/tests", { state: { value: item } })}
                className="frequently_booked_item"
              >
                <p>{item}</p>
              </div>
            );
          })}
        </div>
      </div>
      <div className="top_packages">
        {/* <Tests limit={true} /> */}
        <h1 className="top_packages_h1">Top Tests</h1>
        <div className="top_packages_list">
          {testData
            .filter((data) => data.type === "Test")
            .filter(
              (data) =>
                data.name.includes("CBP") ||
                data.name.includes("FBS (FASTING BLOOD SUGAR)") ||
                data.name.includes(
                  "LFT(LIVER FUNCTION TEST WITHOUT PROTEINS"
                ) ||
                data.name.includes("HBA1C")
            )
            .slice(0, 4)
            .map((item, index) => {
              return <Plancard key={index} item={item} />;
            })}
        </div>
      </div>
      <div className="top_packages">
        {/* <Tests limit={true} /> */}
        <h1 className="top_packages_h1">Top Health Packages</h1>
        <div className="top_packages_list">
          {testData
            .filter((data) => data.type === "Package")
            .filter(
              (data) =>
                data.name.includes("Fever") ||
                data.name.includes("Diabetes") ||
                data.name.includes("Heart") ||
                data.name.includes("Full Body")
            )
            .sort((a, b) => b.name - a.name)
            .slice(0, 4)
            .map((item, index) => {
              return <Plancard key={index} item={item} />;
            })}
        </div>
      </div>
      <WhyChooseUs />
      <div>
        <Contact />
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
}

export default Home;
