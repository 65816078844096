import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { selectUserDetails, setUserDetails } from "../../store";
// import AddMember from '../components/AddMember';
import LoadingDialog from "../../components/LoadingDialog";
import "./AdvantagePlan.css";
import { Add } from "@mui/icons-material";
import { doc, updateDoc } from "firebase/firestore";
import AddMember from "../../components/AddMember/AddMember";

const AdvantagePlan = () => {
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [familyMembers, setFamilyMembers] = useState([]);
  const [userData, setUserData] = useState(null);
  const [activePlan, setActivePlan] = useState(null);
  const [isPlanExpired, setIsPlanExpired] = useState(false);
  const [addMemberOpen, setAddMemberOpen] = useState(false);
  const userDetails = useSelector(selectUserDetails);
  const [loading, setLoading] = useState(false);

  const plans = {
    single: {
      price: 897,
      type: "single",
      points: [{ name: "Only One Member" }],
    },
    couple: {
      price: 1497,
      type: "couple",
      points: [{ name: "Two Members" }],
    },
    family: {
      price: 2597,
      type: "family",
      points: [{ name: "Four Members" }],
    },
  };

  useEffect(() => {
    const fetchUserData = async () => {
      const userDataCache = localStorage.getItem("userDetails");
      if (userDataCache) {
        const data = JSON.parse(userDataCache);
        setUserData(data);

        if (
          data.activePlan &&
          new Date(data.activePlan.validUntil) > new Date()
        ) {
          setActivePlan(data.activePlan);
          setIsPlanExpired(false);
        } else if (data.activePlan) {
          setIsPlanExpired(true);
        }

        setFamilyMembers(data.familyMembers || []);
      }
    };

    fetchUserData();
  }, [userDetails]);

  const handlePurchase = async (planKey) => {
    if (!selectedPlan) {
      alert("Please select a plan first.");
      return;
    }

    if (selectedMembers.length === 0) {
      alert(
        `Please select ${
          planKey === "single" ? "1" : planKey === "couple" ? "2" : "4"
        } family member.`
      );
      return;
    }

    makePayment(
      planKey === "single" ? 897 : planKey === "couple" ? 1497 : 2597
    );
  };

  const createRazorpayOrder = (total) => {
    setLoading(true);
    const key_id = "rzp_live_d9ITSWLvosBZTH";
    const key_secret = "FyH8Lqj6pRRYx6HKhAlUQoaZ";

    const url = "https://api.razorpay.com/v1/orders";
    const data = {
      amount: total * 100,
      currency: "INR",
      receipt: "receipt#1",
    };

    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Basic " + btoa(key_id + ":" + key_secret),
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Order created:", data);
        makePayment(total, data.id);
      })
      .catch((error) => {
        console.error("Error creating order:", error);
        setLoading(false);
      });
  };

  const makePayment = (total, orderId) => {
    var options = {
      key: "rzp_live_d9ITSWLvosBZTH",
      key_secret: "FyH8Lqj6pRRYx6HKhAlUQoaZ",
      image: "https://i.postimg.cc/dtMjJxkW/logo.png",
      amount: total * 100,
      currency: "INR",
      // order_id: orderId,
      name: "Sri Chakra Diagnostics",
      description: "Sri Chakra Diagnostics",
      handler: async function (response) {
        // alert(response.razorpay_payment_id);
        if (
          response.razorpay_payment_id === "undefined" ||
          response.razorpay_payment_id < 1
        ) {
          alert("Payment Failed!");
        } else {
          try {
            const selectedPlanDetails = plans[selectedPlan];
            const validUntil = new Date();
            validUntil.setFullYear(validUntil.getFullYear() + 1);

            const purchaseData = {
              plan: selectedPlan,
              price: selectedPlanDetails.price,
              members: selectedMembers,
              validUntil: validUntil.toISOString(),
            };

            try {
              const userId = userDetails.uid;
              const userDataCache = localStorage.getItem("userDetails");
              const currentUserData = JSON.parse(userDataCache);

              const updatedUserData = {
                ...currentUserData,
                activePlan: purchaseData,
              };

              // Update the user data in Firestore (mocked for this example)
              // await firestore().collection('Users').doc(userId).update({
              //   activePlan: purchaseData,
              // });

              localStorage.setItem(
                "userDetails",
                JSON.stringify(updatedUserData)
              );

              setUserData(updatedUserData);
              setActivePlan(purchaseData);
              setIsPlanExpired(false);
              alert(
                "Purchase Successful! Please Visit Our Branch For More Details."
              );
            } catch (error) {
              alert("Purchase Failed. Please Try Again.");
              console.error(error);
            }
          } catch (error) {
            setLoading(false);
            console.error("Error while booking:", error);
          }
        }
      },
      prefill: {
        email: userDetails.email,
        contact: userDetails.phone,
        name: userDetails.name,
      },
      theme: {
        color: "#002e81",
      },
    };
    var pay = new window.Razorpay(options);
    pay.open();
  };

  const handleMemberSelection = (member, planKey) => {
    if (selectedMembers.some((data) => data.name === member.name)) {
      setSelectedMembers(
        selectedMembers.filter((id) => id.name !== member.name)
      );
    } else if (
      planKey === "single"
        ? selectedMembers.length === 0
        : planKey === "couple"
        ? selectedMembers.length < 2
        : selectedMembers.length < 4
    ) {
      setSelectedMembers([...selectedMembers, member]);
    } else {
      alert(
        `You can only add upto ${
          planKey === "single" ? "1" : planKey === "couple" ? "2" : "4"
        } member in ${planKey} plan.`
      );
    }
  };

  const renderMembersSelection = (planKey) => {
    return (
      <div className="members-selection">
        {[
          ...familyMembers,
          ...familyMembers,
          ...familyMembers,
          ...familyMembers,
          ...familyMembers,
        ].map((item, index) => (
          <button
            key={index}
            className={`member-item ${
              selectedMembers.some((data) => data.name === item.name)
                ? "selected-member-item"
                : ""
            }`}
            onClick={() => handleMemberSelection(item, planKey)}
          >
            {`${item.name} (${item.age}, ${item.relation})`}
          </button>
        ))}
        <button
          className="account_save_btn"
          onClick={() => setAddMemberOpen(true)}
        >
          <Add />
          Add new member
        </button>
      </div>
    );
  };

  return (
    <div className="container">
      <h2 className="header">Sri Chakra Advantage Plans</h2>
      <div className="plans-list">
        {activePlan && (
          <div className="active-plan-container">
            <h3 className="active-plan-text">
              You have an active {activePlan.plan} plan
            </h3>
            <div className="active-plan-details">
              <span className="active-plan-detail">
                Validity:{" "}
                <strong>
                  {new Date(activePlan.validUntil).toLocaleDateString()}
                </strong>
              </span>
              <span className="active-plan-detail">
                Price: <strong>₹{activePlan.price}</strong>
              </span>
            </div>
            <h4>Members</h4>
            <div className="members-list">
              {activePlan.members.map((item, index) => (
                <span key={index} className="member-detail">
                  {`${item.name} (${item.age}, ${item.relation})`}
                </span>
              ))}
            </div>
            {isPlanExpired ? (
              <button
                className="upgrade-button"
                onClick={() => setSelectedPlan(activePlan.plan)}
              >
                Renew Plan
              </button>
            ) : (
              <button
                className="upgrade-button"
                onClick={() => {
                  setSelectedPlan(null);
                  alert("Select a new plan to upgrade.");
                }}
              >
                Upgrade Plan
              </button>
            )}
          </div>
        )}
        <div className="general-info">
          <div className="info-item">
            <span className="check-icon">✔</span> 1 Year Validity
          </div>
          <div className="info-item">
            <span className="check-icon">✔</span> 10% Extra discount on all
            Tests/Packages
          </div>
        </div>
        {Object.keys(plans).map((planKey) => (
          <div
            key={planKey}
            className={`plan-item ${
              selectedPlan === planKey ? "active-plan-item" : ""
            } ${
              activePlan && activePlan.plan === planKey && !isPlanExpired
                ? "disabled-plan-item"
                : ""
            }`}
          >
            <h3
              onClick={() => {
                setSelectedMembers([]);
                setSelectedPlan(planKey);
              }}
              className="plan-title"
            >
              {`${planKey.charAt(0).toUpperCase() + planKey.slice(1)} Plan - ₹${
                plans[planKey].price
              }`}
            </h3>
            <ul className="plan-points">
              {plans[planKey].points.map((point, index) => (
                <li key={index} className="plan-point">
                  <span className="check-icon">✔</span>
                  {point.name}
                </li>
              ))}
            </ul>
            {selectedPlan === planKey && renderMembersSelection(planKey)}
          </div>
        ))}
      </div>
      <button
        className="proceed-button"
        onClick={() => handlePurchase(selectedPlan)}
      >
        Proceed to Payment
      </button>
      {loading && <LoadingDialog />}
      <AddMember open={addMemberOpen} onClose={() => setAddMemberOpen(false)} />
    </div>
  );
};

export default AdvantagePlan;
