import React, { useState, useEffect } from "react";
import {
  getFirestore,
  collection,
  query,
  where,
  getDocs,
  addDoc,
} from "firebase/firestore";
import GopiImg from "../../assets/gopi.JPG";
import PrasadImg from "../../assets/prasad_dr.jpg";
import "./AppointmentScreen.css";
import { useNavigate } from "react-router-dom";
import { selectDoctors } from "../../store";
import { useSelector } from "react-redux";

const AppointmentScreen = () => {
  const navigate = useNavigate();
  const [doctor, setDoctor] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const [time, setTime] = useState("");
  const [name, setName] = useState("");
  const [mobile, setMobile] = useState("");
  const [availableDates, setAvailableDates] = useState([]);
  const [availableTimes, setAvailableTimes] = useState([]);
  const doctors = useSelector(selectDoctors);

  const firestore = getFirestore();

  // // Fetch available doctors
  // useEffect(() => {
  //   const fetchDoctors = async () => {
  //     try {
  //       const doctorsCollection = collection(firestore, "Doctors");
  //       const doctorsQuery = query(doctorsCollection);
  //       const doctorsSnapshot = await getDocs(doctorsQuery);
  //       const doctorData = doctorsSnapshot.docs.map((doc) => doc.data().name);
  //       setDoctors(doctorData);
  //       setDoctor(doctorData[0]);
  //     } catch (error) {
  //       console.error("Error fetching doctors:", error);
  //     }
  //   };

  //   fetchDoctors();
  // }, [firestore]);

  // Fetch available dates from today for the next 5 days
  useEffect(() => {
    const today = new Date();
    const next5Days = Array.from({ length: 5 }, (_, index) => {
      const date = new Date(today);
      date.setDate(today.getDate() + index);
      return date.toISOString().split("T")[0];
    });
    setAvailableDates(next5Days);
    setSelectedDate(next5Days[0]);
  }, []);

  // Fetch available times for a selected date and doctor
  useEffect(() => {
    const fetchAvailableTimes = async () => {
      try {
        const appointmentsCollection = collection(firestore, "Appointments");
        const appointmentsQuery = query(
          appointmentsCollection,
          where("date", "==", selectedDate),
          where("doctorId", "==", doctor)
        );
        const appointmentsSnapshot = await getDocs(appointmentsQuery);

        const bookedTimes = appointmentsSnapshot.docs.map(
          (doc) => doc.data().time
        );
        const allTimes = Array.from({ length: 10 }, (_, index) => {
          const time = index + 8;
          return `${time}:00`;
        });

        const availableTimes = allTimes.filter(
          (time) => !bookedTimes.includes(time)
        );
        setAvailableTimes(availableTimes);
      } catch (error) {
        console.error("Error fetching available times:", error);
      }
    };

    if (selectedDate && doctor) {
      fetchAvailableTimes();
    }
  }, [selectedDate, doctor, firestore]);

  const handleBooking = async () => {
    try {
      const appointmentsCollection = collection(firestore, "Appointments");
      await addDoc(appointmentsCollection, {
        doctor,
        selectedDate,
        time,
      });
      alert("Appointment booked successfully!");
    } catch (error) {
      console.error("Error booking appointment:", error);
    }
  };

  return (
    <div className="appointment_container">
      <div className="appointment_container_doctors">
        <h2 className="appointment_container_head">Book an Appointment</h2>
        <div className="doctors_list">
          {doctors.map((d, index) => (
            <div
              onClick={() => setDoctor(d.id)}
              className={doctor === d.id ? "doctor_item active" : "doctor_item"}
              key={index}
            >
              <img className="doctor_item_img" src={d.imgUrl} />
              <h3>{d.name}</h3>
              <p>{d.degrees}</p>
            </div>
          ))}
        </div>
      </div>
      <div className="appointment_details_div">
        <h3 className="appointment_container_head">Details</h3>
        <label>
          Name
          <input value={name} onChange={(text) => setName(text.target.value)} />
        </label>
        <label>
          Mobile Number
          <input
            type="number"
            value={mobile}
            onChange={(text) => setMobile(text.target.value)}
          />
        </label>
        <div className="date_selection">
          {availableDates.map((date, index) => (
            <button
              className={
                selectedDate === date ? "date_item active" : "date_item"
              }
              key={index}
              onClick={() => setSelectedDate(date)}
            >
              <p>
                {new Date(date).toLocaleString("en-us", {
                  weekday: "short",
                })}
              </p>
              <h4>{new Date(date).getDate()}</h4>
              <p>
                {new Date(date).toLocaleString("default", {
                  month: "short",
                })}
              </p>
            </button>
          ))}
        </div>
        <div className="slot_selection">
          {availableTimes?.map((slot, index) => {
            return (
              <button
                onClick={() => setTime(slot)}
                className={time === slot ? "slot_item active" : "slot_item"}
                key={index}
              >
                {slot}
              </button>
            );
          })}
        </div>
        <button
          className="book_appoint_btn"
          onClick={handleBooking}
          disabled={!selectedDate || !time || !doctor}
        >
          Book Appointment
        </button>
      </div>
    </div>
  );
};

export default AppointmentScreen;
