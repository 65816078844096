import React, { useState, useEffect, useRef } from "react";
import { APIProvider, Map, Marker } from "@vis.gl/react-google-maps";
import PlacesAutocomplete from "react-google-places-autocomplete";
import { Dialog } from "@mui/material";
import { CloseRounded } from "@mui/icons-material";
import PinImg from "../../assets/pin.png";
import "./AddressSelection.css";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../../firebase";
import {
  selectUserDetails,
  setSelectedAddress,
  setUserDetails,
} from "../../store";
import { useDispatch, useSelector } from "react-redux";

const apiKey = "AIzaSyBT45Y77-A7R6eYLFDyNDsZPv0n7-5r9to";

const AddressSelection = ({ addAddressOpen, setAddAddressOpen }) => {
  const userDetails = useSelector(selectUserDetails);
  const dispatch = useDispatch();
  const [map, setMap] = useState(null);
  const [center, setCenter] = useState(null);
  const [zoom, setZoom] = useState(18);
  const [address, setAddress] = useState("");
  const [area, setArea] = useState("");
  const [city, setCity] = useState("");
  const inputRef = useRef(null);
  const mapRef = useRef(null);
  const [searchTask, setSearchTask] = useState(false);
  const [flatNo, setFlatNo] = useState("");
  const [saveAs, setSaveAs] = useState("Home");
  const [loadingDialog, setLoadingDialog] = useState(true);

  const handleMapLoad = (mapInstance) => {
    setMap(mapInstance);
  };

  const handleRegionChange = (event) => {
    const { lat, lng } = event.detail.center;
    setCenter({ lat: lat, lng: lng });
  };

  useEffect(() => {
    handleLocateMePress();
  }, []);

  const handleLocateMePress = async () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setCenter({ lat: latitude, lng: longitude });
          setZoom(18);
          fetchReverseGeocode(longitude, latitude);
        },
        (error) => console.warn("Error getting current location:", error),
        { enableHighAccuracy: true, timeout: 15000, maximumAge: 10000 }
      );
    } else {
      alert("Geolocation is not supported by this browser.");
    }
  };

  const fetchReverseGeocode = async (longitude, latitude) => {
    try {
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${apiKey}`
      );
      const data = await response.json();
      if (data.results && data.results.length > 0) {
        const address = data.results[0];
        const components = address.address_components;

        let locality, city, state, country;
        for (const component of components) {
          const types = component.types;
          if (types.includes("sublocality")) {
            locality = component.long_name;
          } else if (types.includes("locality")) {
            city = component.long_name;
          } else if (types.includes("administrative_area_level_1")) {
            state = component.long_name;
          } else if (types.includes("country")) {
            country = component.long_name;
          }
        }
        setArea(locality || city);
        setAddress(`${city}, ${state}, ${country}`);
        setCity(city);
      }
    } catch (error) {
      console.error("Error fetching reverse geocode:", error);
    }
  };

  const handleSelectSuggestion = async (suggestion) => {
    setSearchTask(true);
    const encodedAddress = encodeURIComponent(suggestion.label);

    const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodedAddress}&key=${apiKey}`;

    try {
      const response = await fetch(url);
      const data = await response.json();
      if (data.status === "OK") {
        const location = data.results[0].geometry.location;
        const formattedAddress = data.results[0].formatted_address;

        setCenter({
          lat: location.lat,
          lng: location.lng,
        });
        setZoom(18);
        setArea(data.results[0].name);
        setAddress(formattedAddress);
        setCity(data.results[0].vicinity || data.results[0].name);
      } else {
        console.error("Error:", data.status);
      }
    } catch (error) {
      console.error("Error fetching address details:", error);
    } finally {
      setSearchTask(false);
    }
  };

  async function handleAddAddress() {
    if (flatNo === "") {
      alert("Please enter House no / Apartment name.");
    } else {
      const userDataCache = await localStorage.getItem("userDetails");
      const userData = {
        addresses: [
          ...JSON.parse(userDataCache).addresses,
          {
            address: address,
            area: area,
            flatNo: flatNo,
            city: city,
            label: saveAs,
            coordinates: center,
          },
        ],
      };
      updateDoc(doc(db, "Users", userDetails?.phone), userData);
      localStorage.setItem(
        "userDetails",
        JSON.stringify({ ...JSON.parse(userDataCache), ...userData })
      );
      dispatch(setUserDetails({ ...JSON.parse(userDataCache), ...userData }));
      dispatch(
        setSelectedAddress({
          address: address,
          area: area,
          flatNo: flatNo,
          city: city,
          label: saveAs,
          coordinates: center,
        })
      );
      setAddAddressOpen(false);
    }
  }

  return (
    <Dialog open={addAddressOpen} onClose={() => setAddAddressOpen(false)}>
      <button
        className="tests_container_close_btn"
        onClick={() => setAddAddressOpen(false)}
      >
        <CloseRounded />
      </button>
      <div className="dialog-container">
        <div className="autocomplete-container">
          <PlacesAutocomplete
            selectProps={{
              onChange: handleSelectSuggestion,
              className: "autocomplete-container-input",
            }}
          >
            {({ getInputProps }) => (
              <input
                {...getInputProps({
                  placeholder: "Search for a city or location...",
                })}
              />
            )}
          </PlacesAutocomplete>
        </div>
        <APIProvider apiKey={apiKey}>
          {center && (
            <Map
              ref={mapRef}
              mapContainerStyle={{ width: "100%", height: "100%" }}
              zoom={zoom}
              center={center}
              onMapLoad={handleMapLoad}
              onCameraChanged={handleRegionChange}
              onDragend={() => fetchReverseGeocode(center.lng, center.lat)}
              fullscreenControl={false}
              zoomControl={false}
              streetView={false}
              mapTypeControl={false}
              streetViewControl={false}
            >
              <button className="locate_me_btn" onClick={handleLocateMePress}>
                Locate Me
              </button>
              <div className="map-pin">
                <img src={PinImg} alt="Pin" />
              </div>
            </Map>
          )}
        </APIProvider>
        <div className="address_selection_sheet">
          <p>{area}</p>
          <p>{address}</p>
          <label>
            House no | Apartment name *
            <input
              type="text"
              placeholder="House no | Apartment name *"
              value={flatNo}
              onChange={(e) => setFlatNo(e.target.value)}
            />
          </label>
          <div>
            {["Home", "Parent's", "Spouse", "Others"].map((item, index) => (
              <p
                key={index}
                onClick={() => setSaveAs(item)}
                className={
                  saveAs === item
                    ? "save_as_selected_active"
                    : "save_as_selected"
                }
              >
                {item}
              </p>
            ))}
          </div>
          <button onClick={handleAddAddress}>Confirm</button>
        </div>
      </div>
    </Dialog>
  );
};

export default AddressSelection;
