import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";

function LoadingDialog({ open, onClose }) {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogContent>
        <p>Loading...</p>
      </DialogContent>
    </Dialog>
  );
}

export default LoadingDialog;
