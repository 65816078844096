import React, { useEffect, useState } from "react";

function ReportItem({ report, index }) {
  const [reportUrl, setReportUrl] = useState("");
  const [selectedReport, setSelectedReport] = useState([]);

  const handleRecordSelect = (index) => {
    if (selectedReport.includes(index)) {
      setSelectedReport(selectedReport?.filter((data) => data !== index));
    } else {
      setSelectedReport([...selectedReport, index]);
    }
  };

  async function viewReport(visitId) {
    const apiUrl = "http://103.118.16.78:8081/api/ViewReport";

    const requestData = {
      VisitId: visitId,
    };

    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestData),
      });

      if (response.ok) {
        const data = await response.json();
        if (data.Status === "Success" && data.PDfPath) {
          setReportUrl(data.PDfPath);
          console.log(data.PDfPath);
        } else {
          console.error("Failed to view the report:", data.Message);
        }
      } else {
        console.error("Failed to view the report.");
      }
    } catch (error) {
      console.error("Error viewing the report:", error);
    }
  }

  // useEffect(() => {
  //   viewReport(report?.VisitId);
  // }, []);

  return (
    <tr
      className={`record_item ${
        selectedReport.includes(index) ? "selected" : ""
      }`}
    >
      <td>
        <input
          type="radio"
          value={index}
          style={{ marginRight: "0.5rem", cursor: "pointer" }}
          checked={selectedReport.includes(index)}
          onClick={() => handleRecordSelect(index)}
        />
        {index}
      </td>
      <td>{report?.Date}</td>
      <td>{report?.MedhaId}</td>
      <td>{report?.VisitId}</td>
      <td>{report?.Name}</td>
      <td>{report?.Gender}</td>
      <td>{report?.Age}</td>
      <td>{report?.RefBy}</td>
      <td>{report?.Tests}</td>
      <td>{report?.invoice}</td>
      <td>
        <a target="_blank" href={reportUrl}>
          View Report
        </a>
      </td>
      <td>{report?.Remarks}</td>
      <td>{report?.Status}</td>
      <td>{report?.DueAmount}</td>
    </tr>
  );
}

export default ReportItem;
