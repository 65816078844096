import { configureStore } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  auth: false,
  cart: [],
  city: "",
  userDetails: {},
  activeTab: "Home",
  tests: [],
  medicalRecords: [],
  bookings: [],
  loginDialog: false,
  doctors: [],
  selectedAddress: null,
};

export const mainSlice = createSlice({
  name: "maindb",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setAuth: (state, data) => {
      state.auth = data.payload;
    },
    setSelectedAddress: (state, data) => {
      state.selectedAddress = data.payload;
    },
    setCity: (state, data) => {
      state.city = data.payload;
    },
    setCart: (state, data) => {
      state.cart = data.payload;
    },
    setUserDetails: (state, data) => {
      state.userDetails = data.payload;
    },
    setActiveTab: (state, data) => {
      state.activeTab = data.payload;
    },
    setTests: (state, data) => {
      state.tests = data.payload;
    },
    setMedicalRecords: (state, data) => {
      state.medicalRecords = data.payload;
    },
    setBookings: (state, data) => {
      state.bookings = data.payload;
    },
    setLoginDialog: (state, data) => {
      state.loginDialog = data.payload;
    },
    setDoctors: (state, data) => {
      state.doctors = data.payload;
    },
  },
});

export const {
  setAuth,
  setCart,
  setCity,
  setUserDetails,
  setActiveTab,
  setTests,
  setMedicalRecords,
  setBookings,
  setLoginDialog,
  setDoctors,
  setSelectedAddress,
} = mainSlice.actions;

export const selectAuth = (state) => state.auth;
export const selectCart = (state) => state.cart;
export const selectCity = (state) => state.city;
export const selectUserDetails = (state) => state.userDetails;
export const selectActiveTab = (state) => state.activeTab;
export const selectTests = (state) => state.tests;
export const selectMedicalRecords = (state) => state.medicalRecords;
export const selectBookings = (state) => state.bookings;
export const selectLoginDialog = (state) => state.loginDialog;
export const selectDoctors = (state) => state.doctors;
export const selectSelectedAddress = (state) => state.selectedAddress;

export const store = configureStore({
  reducer: mainSlice.reducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
