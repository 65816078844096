// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCk7Q6FnGPuGv5sYd-BGXZ6Cj37TnXaD9E",
  authDomain: "srichakrahms.firebaseapp.com",
  projectId: "srichakrahms",
  storageBucket: "srichakrahms.appspot.com",
  messagingSenderId: "818582548421",
  appId: "1:818582548421:web:74a2ba669265440c0aa5ac",
  measurementId: "G-BSDTR2VB39",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore(app);
const auth = getAuth(app);

export { db, auth };
