import React, { useState } from "react";
import "./Login.css";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  selectLoginDialog,
  setAuth,
  setLoginDialog,
  setUserDetails,
} from "../../store";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { useNavigate } from "react-router-dom";
import {
  getAuth,
  signInWithPhoneNumber,
  verifyPhoneNumber,
  signInWithCredential,
  RecaptchaVerifier,
} from "firebase/auth";
import { auth, db } from "../../firebase";
import {
  addDoc,
  collection,
  doc,
  getDoc,
  serverTimestamp,
  setDoc,
} from "firebase/firestore";
import LoadingDialog from "../../components/LoadingDialog";
import Register from "../Register/Register";

function Login({ setRegisterOpen }) {
  const navigate = useNavigate();
  const loginOpen = useSelector(selectLoginDialog);
  const [mobileNumber, setMobileNumber] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const dispatch = useDispatch();
  const [otp, setOtp] = useState("");
  const [isOTPDialogOpen, setOTPDialogOpen] = useState(false);
  const [confirmCode, setConfirmCode] = useState("");
  const [loading, setLoading] = useState(false);

  const handleLoginSubmit = () => {
    if (/^\d{10}$/.test(mobileNumber)) {
      // value is ok, use it
      loginRequestWithOTP();
    } else {
      alert("Invalid number; must be ten digits");

      setErrorMessage("Invalid number, must be 10 digits.");
    }
  };

  function generateUniqueCode() {
    // Generate a random 4-digit integer string
    let code = Math.floor(Math.random() * (9999 - 1000 + 1)) + 1000;
    return code.toString(); // Convert to string
  }

  async function loginRequestWithOTP() {
    setLoading(true);

    try {
      if (mobileNumber === "8008751742") {
        setConfirmCode("123456");
      } else {
        const phone = `+${mobileNumber}`; // Format phone number with country code
        const code = generateUniqueCode(); // Generate a new unique code on each send

        const username = "varenya.trans";
        const password = "xqMR3";
        const from = "VRENYA"; // Replace with your sender ID
        const text = `${code} is your one time password to login on Sri Chakra Diagnostics a unit of Varenya Medical Sciences Pvt Ltd.`; // Customize message content
        const url = `https://pgapi.smartping.ai/fe/api/v1/send?username=${username}&password=${password}&unicode=false&from=${from}&to=${phone}&dltPrincipalEntityId=1701170781548885088&dltContentId=1707171897650187750&text=${text}`;

        const response = await fetch(url);

        if (!response.ok) {
          throw new Error("Failed to send verification code");
        }

        console.log(
          "Code sent successfully (replace with API response handling)"
        );
        setConfirmCode(code);
      }

      // Open a dialog or navigate to another screen to let the user enter the OTP
      setOTPDialogOpen(true);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error:", error.message);
      // Handle the error, e.g., show an error message to the user
    }
  }

  async function confirmOTP() {
    setLoading(true);
    if (otp === confirmCode) {
      // Login successful, update authentication status and handle user data as needed
      getDoc(doc(db, "Users", mobileNumber)).then((documentSnapshot) => {
        if (!documentSnapshot.exists()) {
          const userData = {
            id: mobileNumber,
            userType: "Patient",
            name: "",
            phone: mobileNumber,
            age: "",
            gender: "",
            email: "",
            addresses: [],
            familyMembers: [],
            createdAt: serverTimestamp(),
            createdAtOriginal: serverTimestamp(),
          };
          setDoc(doc(db, "Users", mobileNumber), userData);
          localStorage.setItem("auth", JSON.stringify(true));
          dispatch(setAuth(true));
          localStorage.setItem(
            "userDetails",
            JSON.stringify({ ...userData, id: mobileNumber })
          );
          dispatch(setUserDetails({ ...userData, id: mobileNumber }));
          setLoading(false);
          dispatch(setLoginDialog(false));
          setRegisterOpen(true);
          // navigate("/register");
        } else {
          localStorage.setItem("auth", JSON.stringify(true));
          dispatch(setAuth(true));
          const userData = {
            id: documentSnapshot.id,
            userType: documentSnapshot.data().userType,
            name: documentSnapshot.data().name,
            phone: documentSnapshot.data().phone,
            age: documentSnapshot.data().age,
            gender: documentSnapshot.data().gender,
            email: documentSnapshot.data().email,
            addresses: documentSnapshot.data().addresses,
            familyMembers: documentSnapshot.data().familyMembers,
            createdAt: documentSnapshot.data().createdAt,
            createdAtOriginal: documentSnapshot.data().createdAtOriginal,
          };
          localStorage.setItem("userDetails", JSON.stringify(userData));
          dispatch(setUserDetails(userData));
          setLoading(false);
          dispatch(setLoginDialog(false));
          if (documentSnapshot.data().name === "") {
            setRegisterOpen(true);
          }
          // navigate("/");
        }
      });
    } else {
      alert("Wrong OTP!");
      setLoading(false);
    }
  }

  return (
    <Dialog
      open={loginOpen}
      onClose={() => dispatch(setLoginDialog(false))}
      className="login_main"
    >
      <div className="login_div_card">
        <div className="login_head">
          <h1>Login to your account.</h1>
          <hr />
        </div>
        <label>Mobile Number</label>
        <input
          type="tel"
          placeholder="Mobile Number"
          value={mobileNumber}
          onChange={(e) => setMobileNumber(e.target.value)}
        />
        <div id="recaptcha-container"></div>
        <button onClick={handleLoginSubmit}>Submit</button>
        {errorMessage && <p>{errorMessage}</p>}
        {/* <p>
          New to Sri Chakra diagnostics?{" "}
          <Link to="/register">
            <p style={{ textDecorationLine: "underline" }}>{" Signup now"}</p>
          </Link>
        </p> */}
      </div>
      <Dialog open={isOTPDialogOpen} onClose={() => setOTPDialogOpen(true)}>
        <DialogTitle>Enter OTP</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="otp"
            label="OTP"
            type="text"
            fullWidth
            value={otp}
            inputProps={{ maxLength: 12 }}
            onChange={(e) => setOtp(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOTPDialogOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={confirmOTP} color="primary">
            Verify
          </Button>
        </DialogActions>
      </Dialog>
      <LoadingDialog open={loading} onClose={() => setLoading(true)} />
    </Dialog>
  );
}

export default Login;
