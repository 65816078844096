import React, { useEffect, useState } from "react";
import "./Addresses.css";
import { useDispatch, useSelector } from "react-redux";
import { doc, updateDoc } from "firebase/firestore";
import { auth, db } from "../../firebase";
import {
  selectAuth,
  selectSelectedAddress,
  selectUserDetails,
  setSelectedAddress,
  setUserDetails,
} from "../../store";
import { Dialog } from "@mui/material";
import AddressItem from "../../pages/Account/AddressItem";
import AddressSelection from "../AddressSelection/AddressSelection";

function Addresses({ setAddressOpen, addressOpen }) {
  const userData = useSelector(selectUserDetails);
  const authed = useSelector(selectAuth);
  const selectedAddress = useSelector(selectSelectedAddress);
  const dispatch = useDispatch();
  const [addAddressOpen, setAddAddressOpen] = useState(false);

  useEffect(() => {
    if (authed && !selectedAddress) {
      dispatch(setSelectedAddress(userData.addresses[0]));
    }
  }, [userData]);

  return (
    <Dialog open={addressOpen} onClose={() => setAddressOpen(false)}>
      <div className="addresses_div_dialog">
        <h2>Manage Addresses</h2>
      </div>
      {userData?.addresses?.length !== 0 ? (
        <>
          {userData?.addresses?.map((item, index) => {
            return <AddressItem key={index} item={item} />;
          })}
        </>
      ) : (
        <p>No Addresses Found. Please Add One</p>
      )}
      <button
        className="account_save_btn"
        onClick={() => setAddAddressOpen(true)}
      >
        Add Address
      </button>
      <AddressSelection
        addAddressOpen={addAddressOpen}
        setAddAddressOpen={setAddAddressOpen}
      />
    </Dialog>
  );
}

export default Addresses;
