import React, { useState } from "react";
import "./Account.css";
import { EditOutlined } from "@mui/icons-material";
import { Dialog } from "@mui/material";
import { auth, db } from "../../firebase";
import { doc, updateDoc } from "firebase/firestore";
import { selectUserDetails, setUserDetails } from "../../store";
import { useDispatch, useSelector } from "react-redux";

function MemberItem({ item }) {
  const [addMemberOpen, setAddMemberOpen] = useState(false);
  const [memberName, setMemberName] = useState(item.name);
  const [memberGender, setMemberGender] = useState(item.gender);
  const [memberRelation, setMemberRelation] = useState(item.relation);
  const [age, setAge] = useState(item.age);
  const dispatch = useDispatch();
  const userDetails = useSelector(selectUserDetails);

  async function handleAddFamilyMembers() {
    const userDataCache = localStorage.getItem("userDetails");
    const userData = {
      familyMembers: [
        ...JSON.parse(userDataCache).familyMembers?.filter(
          (data) => data.name !== item.name
        ),
        {
          name: memberName,
          gender: memberGender,
          age: age,
          relation: memberRelation,
        },
      ],
    };
    updateDoc(doc(db, "Users", userDetails.phone), userData);
    localStorage.setItem(
      "userDetails",
      JSON.stringify({ ...JSON.parse(userDataCache), ...userData })
    );
    dispatch(setUserDetails({ ...JSON.parse(userDataCache), ...userData }));
    setAddMemberOpen(false);
    setMemberGender("");
    setMemberName("");
    setMemberRelation("");
    setAge("");
  }

  return (
    <div className="address_item">
      <h2>{item.name}</h2>
      <p>{"Gender: " + item.gender + " | Age: " + item.age}</p>
      <p>{"How are you related: " + item.relation}</p>
      <button onClick={() => setAddMemberOpen(true)} className="edit_icon">
        <EditOutlined />
      </button>
      <Dialog open={addMemberOpen} onClose={() => setAddMemberOpen(false)}>
        <div className="addresses_div_dialog">
          <h2>Add Member</h2>
          <label>
            Full Name*
            <input
              type="text"
              placeholder="Enter Full Name"
              value={memberName}
              onChange={(e) => setMemberName(e.target.value)}
            />
          </label>
          <div className="profile_input_group">
            <label>
              Gender
              <select
                value={memberGender}
                onChange={(e) => setMemberGender(e.target.value)}
              >
                <option value="">Select Gender</option>
                <option value="Male">Male</option>
                <option value="Female">Female</option>
                {/* Add your gender options here */}
              </select>
            </label>
            <label>
              Age*
              <input
                type="text"
                placeholder="Enter Age"
                value={age}
                onChange={(e) => setAge(e.target.value)}
              />
            </label>
          </div>
          <label>
            Relation*
            <select
              value={memberRelation}
              onChange={(e) => setMemberRelation(e.target.value)}
            >
              <option value="">Select Relation</option>
              <option value="Self">Self</option>
              <option value="Spouse">Spouse</option>
              <option value="Mother">Mother</option>
              <option value="Father">Father</option>
              <option value="Daughter">Daughter</option>
              <option value="Son">Son</option>
              <option value="GrandParents">GrandParents</option>
              <option value="Siblings">Siblings</option>
              <option value="In-Laws">In-Laws</option>
              <option value="Other">Other</option>
            </select>
          </label>
          <button className="account_save_btn" onClick={handleAddFamilyMembers}>
            Add Member
          </button>
        </div>
      </Dialog>
    </div>
  );
}

export default MemberItem;
