import React, { useEffect } from "react";
import "./Test.css";
import Plancard from "../plan/Plancard";
import { useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { selectTests, setTests } from "../../store";
import {
  Timestamp,
  collection,
  doc,
  getDocs,
  query,
  serverTimestamp,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";
import { db } from "../../firebase";
import { CloseRounded, Search } from "@mui/icons-material";
import { Dialog } from "@mui/material";

export default function Tests({ limit }) {
  const location = useLocation();
  const testData = useSelector(selectTests);
  const dispatch = useDispatch();
  const [testPackageFilter, setTestPackageFilter] = useState("");
  const [visitFilter, setVisitFilter] = useState("");
  const [specializationFilter, setspecializationFilter] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTxt, setSearchTxt] = useState(
    location.state?.value === undefined || location.state?.value === null
      ? ""
      : location.state?.value
  );
  const [filtersOpen, setFiltersOpen] = useState(false);

  useEffect(() => {
    applyFilters(testPackageFilter, visitFilter, specializationFilter);
    // fetchTestFilters();
  }, [
    testPackageFilter,
    visitFilter,
    specializationFilter,
    searchTxt,
    testData,
  ]);

  const applyFilters = (
    testPackageFilter,
    visitFilter,
    specializationFilter
  ) => {
    setLoading(true);
    setFilteredData(
      testData?.filter((data) => {
        if (
          (testPackageFilter !== "" ? data.type === testPackageFilter : data) &&
          (visitFilter !== "" ? data.visitType === visitFilter : data) &&
          (specializationFilter !== ""
            ? data.deptname === specializationFilter
            : data) &&
          data.name.toLowerCase().includes(searchTxt.toLowerCase())
        ) {
          return data;
        }
      })
    );
    setTimeout(() => {
      setLoading(false);
    }, 500);
  };

  useEffect(() => {
    // addDiscount(testData.filter((data) => data.deptname === "Radiology"));
    // updateDoc(doc(db, "TestsPackages", "SCDDATA"), {
    //   data: testData.map((data) => ({
    //     ...data,
    //     id: data.code,
    //   })),
    //   createdAt: serverTimestamp(),
    // });
  }, []);

  function addDiscount(jsonData) {
    const keysToRemove = ["createdAt", "createdAtOriginal"];
    const dataWithoutKeys = removeKeys(
      [
        ...testData.filter((data) => data.deptname !== "Radiology"),
        ...jsonData.map((data) => ({
          ...data,
          price: JSON.stringify(Number(data.mrp) - Number(data.mrp) * 0.15),
        })),
      ],
      keysToRemove
    );
    console.log(dataWithoutKeys);
    setDoc(doc(db, "TestsPackages", "SCDDATA"), {
      createdAtOriginal: serverTimestamp(),
      createdAt: serverTimestamp(),
      data: dataWithoutKeys,
    });
  }

  function removeKeys(data, keysToRemove) {
    return data.map((obj) => {
      // Create a new object without the unwanted keys
      const newObj = { ...obj };
      keysToRemove.forEach((key) => delete newObj[key]);
      return newObj;
    });
  }

  return (
    <div className="test">
      <div className="tests_heading">
        <h2>Tests/Packages</h2>
        <button
          className="clear_all_btn"
          style={{ marginLeft: "1rem" }}
          onClick={() => {
            setFiltersOpen(true);
          }}
        >
          Filters
        </button>
      </div>
      <div className="test_main">
        <div className="filters_container">
          <h2>
            Filters
            <button
              className="clear_all_btn"
              onClick={() => {
                setTestPackageFilter("");
                setVisitFilter("");
                setspecializationFilter("");
              }}
            >
              Clear All
            </button>
          </h2>
          <div className="filter_item">
            <h4>Test Type</h4>
            <div className="filter_item_btns_grp">
              <button
                onClick={() => setTestPackageFilter("Test")}
                className={
                  testPackageFilter === "Test"
                    ? "filter_item_btn active"
                    : "filter_item_btn"
                }
              >
                Single Test
              </button>
              <button
                onClick={() => setTestPackageFilter("Package")}
                className={
                  testPackageFilter === "Package"
                    ? "filter_item_btn active"
                    : "filter_item_btn"
                }
              >
                Test Package
              </button>
            </div>
          </div>
          <div className="filter_item">
            <h4>Visit Type</h4>
            <div className="filter_item_btns_grp">
              <button
                onClick={() => setVisitFilter("Home")}
                className={
                  visitFilter === "Home"
                    ? "filter_item_btn active"
                    : "filter_item_btn"
                }
              >
                Home Visit
              </button>
              <button
                onClick={() => setVisitFilter("Center")}
                className={
                  visitFilter === "Center"
                    ? "filter_item_btn active"
                    : "filter_item_btn"
                }
              >
                Center Visit
              </button>
            </div>
          </div>
          <div className="filter_item">
            <h4>Department</h4>

            <div className="filter_item_btns_grp">
              <button
                onClick={() => setspecializationFilter("Pathology")}
                className={
                  specializationFilter === "Pathology"
                    ? "filter_item_btn active"
                    : "filter_item_btn"
                }
              >
                Pathology
              </button>
              <button
                onClick={() => setspecializationFilter("Radiology")}
                className={
                  specializationFilter === "Radiology"
                    ? "filter_item_btn active"
                    : "filter_item_btn"
                }
              >
                Radiology
              </button>
              <button
                onClick={() => setspecializationFilter("Cardiology")}
                className={
                  specializationFilter === "Cardiology"
                    ? "filter_item_btn active"
                    : "filter_item_btn"
                }
              >
                Cardiology
              </button>
              <button
                onClick={() => setspecializationFilter("Bio Chemistry")}
                className={
                  specializationFilter === "Bio Chemistry"
                    ? "filter_item_btn active"
                    : "filter_item_btn"
                }
              >
                Bio Chemistry
              </button>
              <button
                onClick={() => setspecializationFilter("Micro Biology")}
                className={
                  specializationFilter === "Micro Biology"
                    ? "filter_item_btn active"
                    : "filter_item_btn"
                }
              >
                Micro Biology
              </button>
              <button
                onClick={() => setspecializationFilter("Neurology")}
                className={
                  specializationFilter === "Neurology"
                    ? "filter_item_btn active"
                    : "filter_item_btn"
                }
              >
                Neurology
              </button>
            </div>
          </div>
        </div>
        <div className="test_container">
          <input
            type="text"
            className="search_input"
            placeholder="Tests/Packages"
            value={searchTxt}
            onChange={(text) => {
              setSearchTxt(text.target.value);
            }}
          />
          {loading ? (
            <div>
              <p>Loading</p>
            </div>
          ) : (
            <div className="tests_list">
              {filteredData
                .slice(0, limit ? 8 : undefined)
                .map((item, index) => {
                  return <Plancard key={index} item={item} />;
                })}
            </div>
          )}
        </div>
      </div>
      <Dialog
        onClose={() => setFiltersOpen(false)}
        maxWidth="xl"
        open={filtersOpen}
        className="tests_container"
      >
        <button onClick={() => setFiltersOpen(false)}>
          <CloseRounded />
        </button>
        <div className="filters_container_mobile">
          <h2>
            Filters
            <button
              className="clear_all_btn"
              onClick={() => {
                setTestPackageFilter("");
                setVisitFilter("");
                setspecializationFilter("");
              }}
            >
              Clear All
            </button>
          </h2>
          <div className="filter_item">
            <h4>Test Type</h4>
            <div className="filter_item_btns_grp">
              <button
                onClick={() => setTestPackageFilter("Test")}
                className={
                  testPackageFilter === "Test"
                    ? "filter_item_btn active"
                    : "filter_item_btn"
                }
              >
                Single Test
              </button>
              <button
                onClick={() => setTestPackageFilter("Package")}
                className={
                  testPackageFilter === "Package"
                    ? "filter_item_btn active"
                    : "filter_item_btn"
                }
              >
                Test Package
              </button>
            </div>
          </div>
          <div className="filter_item">
            <h4>Visit Type</h4>
            <div className="filter_item_btns_grp">
              <button
                onClick={() => setVisitFilter("Home")}
                className={
                  visitFilter === "Home"
                    ? "filter_item_btn active"
                    : "filter_item_btn"
                }
              >
                Home Visit
              </button>
              <button
                onClick={() => setVisitFilter("Center")}
                className={
                  visitFilter === "Center"
                    ? "filter_item_btn active"
                    : "filter_item_btn"
                }
              >
                Center Visit
              </button>
            </div>
          </div>
          <div className="filter_item">
            <h4>Department</h4>

            <div className="filter_item_btns_grp">
              <button
                onClick={() => setspecializationFilter("Pathology")}
                className={
                  specializationFilter === "Pathology"
                    ? "filter_item_btn active"
                    : "filter_item_btn"
                }
              >
                Pathology
              </button>
              <button
                onClick={() => setspecializationFilter("Radiology")}
                className={
                  specializationFilter === "Radiology"
                    ? "filter_item_btn active"
                    : "filter_item_btn"
                }
              >
                Radiology
              </button>
              <button
                onClick={() => setspecializationFilter("Cardiology")}
                className={
                  specializationFilter === "Cardiology"
                    ? "filter_item_btn active"
                    : "filter_item_btn"
                }
              >
                Cardiology
              </button>
              <button
                onClick={() => setspecializationFilter("Bio Chemistry")}
                className={
                  specializationFilter === "Bio Chemistry"
                    ? "filter_item_btn active"
                    : "filter_item_btn"
                }
              >
                Bio Chemistry
              </button>
              <button
                onClick={() => setspecializationFilter("Micro Biology")}
                className={
                  specializationFilter === "Micro Biology"
                    ? "filter_item_btn active"
                    : "filter_item_btn"
                }
              >
                Micro Biology
              </button>
              <button
                onClick={() => setspecializationFilter("Neurology")}
                className={
                  specializationFilter === "Neurology"
                    ? "filter_item_btn active"
                    : "filter_item_btn"
                }
              >
                Neurology
              </button>
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  );
}
