import React, { useEffect, useState } from "react";
import "./Account.css";
import { useDispatch, useSelector } from "react-redux";
import {
  selectAuth,
  selectBookings,
  selectCart,
  selectUserDetails,
  setAuth,
  setBookings,
  setCart,
  setMedicalRecords,
  setTests,
  setUserDetails,
} from "../../store";
import { Link, useNavigate, useNavigation } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Add, AddCircle, CloseRounded } from "@mui/icons-material";
import { Dialog } from "@mui/material";
import ReportItem from "./ReportItem";
import {
  Timestamp,
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { auth, db } from "../../firebase";
import AddressItem from "./AddressItem";
import MemberItem from "./MemberItem";
import AddressSelection from "../../components/AddressSelection/AddressSelection";

const options = ["Edit", "Delete"];

const Account = () => {
  const userData = useSelector(selectUserDetails);
  const cartData = useSelector(selectCart);
  const authed = useSelector(selectAuth);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("profile");
  const [medicalReports, setMedicalReports] = useState([]);
  const appointmentsList = useSelector(selectBookings);
  const [medhaPlansList, setMedhaPlansList] = useState({});
  const [selectedMapPlans, setSelectedMapPlans] = useState([]);
  const [selectedAppointment, setSelectedAppointment] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [mobile, setMobile] = useState(userData?.phone);
  const [salutation, setSalutation] = useState(userData?.salutation);
  const [name, setName] = useState(userData?.name);
  const [gender, setGender] = useState(userData?.gender);
  const [dateOfBirth, setDateOfBirth] = useState(userData?.age);
  const [email, setEmail] = useState(userData?.email);
  const [saveAs, setSaveAs] = useState("Home");
  const [address, setAddress] = useState("");
  const [area, setArea] = useState("");
  const [city, setCity] = useState("Warangal");
  const [pincode, setPincode] = useState("506002");
  const [state, setState] = useState("Telangana");
  const [position, setPosition] = useState({ latitude: null, longitude: null });

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [phlebotmistListOpen, setphlebotmistListOpen] = useState(false);
  const [addAddressOpen, setAddAddressOpen] = useState(false);

  const [addMemberOpen, setAddMemberOpen] = useState(false);
  const [memberName, setMemberName] = useState("");
  const [memberGender, setMemberGender] = useState("");
  const [memberRelation, setMemberRelation] = useState("");
  const [age, setAge] = useState("");

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [selectedAddress, setSelectedAddress] = useState(null);

  const handleAddressSelect = (index) => {
    setSelectedAddress(index);
  };

  async function handleSaveSubmit() {
    const userDataLocal = {
      name: name,
      phone: mobile,
      age: dateOfBirth,
      gender: gender,
      email: email,
    };
    updateDoc(doc(db, "Users", auth.currentUser.uid), userDataLocal);
    const userDataCache = localStorage.getItem("userDetails");
    localStorage.setItem(
      "userDetails",
      JSON.stringify({ ...JSON.parse(userDataCache), ...userData })
    );
    dispatch(setUserDetails({ ...JSON.parse(userDataCache), ...userData }));
    alert("Succesfully Changed!");
  }

  const handleTabChange = (tab) => {
    setActiveTab(tab);
    // setSelectedReport([]);
    setSelectedAppointment([]);
  };

  const handleMapSelect = (index) => {
    if (selectedMapPlans.includes(index)) {
      setSelectedMapPlans(selectedMapPlans?.filter((data) => data !== index));
    } else {
      setSelectedMapPlans([...selectedMapPlans, index]);
    }
  };

  const handleAppointmentSelect = (index) => {
    if (selectedAppointment.includes(index)) {
      setSelectedAppointment(
        selectedAppointment?.filter((data) => data !== index)
      );
    } else {
      setSelectedAppointment([...selectedAppointment, index]);
    }
  };

  const handleDownloadAll = () => {
    // Download logic for selected reports
  };

  const filteredReports = appointmentsList?.filter((appointment, index) => {
    return (
      appointment?.status === "Reports are ready" &&
      appointment?.patientName.toLowerCase().includes(searchQuery.toLowerCase())
    );
  });

  const filteredAppointments = appointmentsList?.filter(
    (appointment, index) => {
      return (
        appointment?.status !== "Reports are ready" &&
        appointment?.id.toLowerCase().includes(searchQuery.toLowerCase())
      );
    }
  );

  // useEffect(() => {
  //   fetchMedicalRecords();
  // }, [fromDate, toDate, searchQuery]);

  useEffect(() => {
    const auth = localStorage.getItem("auth");
    dispatch(setAuth(JSON.parse(auth)));
    const userDetails = localStorage.getItem("userDetails");
    dispatch(setUserDetails(JSON.parse(userDetails)));
    setMobile(JSON.parse(userDetails).phone);
    setName(JSON.parse(userDetails).name);
    setGender(JSON.parse(userDetails).gender);
    setDateOfBirth(JSON.parse(userDetails).age);
    setEmail(JSON.parse(userDetails).email);

    const bookingsCacheData = localStorage.getItem("bookings");
    if (JSON.parse(bookingsCacheData) === null) {
      localStorage.setItem("bookings", JSON.stringify([]));
    }
    if (authed) {
      const bookingsLastDateData = localStorage.getItem("bookingsLastDate");
      if (bookingsLastDateData === null) {
        localStorage.setItem(
          "bookingsLastDate",
          JSON.stringify({ nanoseconds: 0, seconds: 700284600 })
        );
        getAppointments({ nanoseconds: 0, seconds: 700284600 });
      } else {
        getAppointments(JSON.parse(bookingsLastDateData));
      }
    }

    // getMedhaPlans();
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(function (position) {
        setPosition({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        });
      });
    } else {
      console.log("Geolocation is not available in your browser.");
    }
  }, [cartData]);

  async function getAppointments(bookingsLastDate) {
    if (bookingsLastDate !== undefined) {
      var startfulldate = Timestamp.fromDate(
        new Date(
          bookingsLastDate.seconds * 1000 +
            bookingsLastDate.nanoseconds / 1000000
        )
      );
      await getDocs(
        query(
          collection(db, "Bookings"),
          where("userId", "==", userData?.id),
          where("createdAt", ">", startfulldate)
        )
      ).then((documentSnapshot) => {
        var arr = [];
        var arrLastId = [];
        var arrBookings = [];
        if (!documentSnapshot.empty) {
          documentSnapshot.forEach((data) => {
            arr.push(data.data().createdAt);
          });
          documentSnapshot.forEach((data) => {
            arrLastId.push(data.data().adId);
          });
          localStorage.setItem(
            "bookingsLastDate",
            JSON.stringify(arr.sort((a, b) => b - a)[0])
          );
          console.log("avail");
        } else {
          console.log("empty");
        }

        const bookingsData = localStorage.getItem("bookings");
        let arrCache = [];
        arrCache.push(JSON.parse(bookingsData));
        if (arrCache[0].length > 0) {
          arrCache[0].forEach((ad) => {
            arrBookings.push(ad);
          });
        }

        if (!documentSnapshot.empty) {
          documentSnapshot.docs.forEach((tests) => {
            if (
              arrBookings.filter((data) => data.id === tests.id).length === 0
            ) {
              console.log("not exist");
              arrBookings.push({
                id: tests.id,
                bookings: tests.data().bookings,
                bookingType: tests.data().bookingType,
                mobileNo: tests.data().mobileNo,
                branchId: tests.data().branchId,
                slotDate: tests.data().slotDate,
                slotTime: tests.data().slotTime,
                refBy: tests.data().refBy,
                email: tests.data().email,
                address: tests.data().address,
                area: tests.data().area,
                city: tests.data().city,
                pinCode: tests.data().pinCode,
                paymentMode: tests.data().paymentMode,
                remarks: tests.data().remarks,
                phlebotomist: tests.data().phlebotomist,
                phlebotomistId: tests.data().phlebotomistId,
                phlebotomistNumber: tests.data().phlebotomistNumber,
                userId: tests.data().userId,
                createdAt: tests.data().createdAt,
                createdAtOriginal: tests.data().createdAtOriginal,
                status: tests.data().status,
                reportLink: tests.data().reportLink,
                totalPrice: tests.data().totalPrice,
              });
            } else {
              const newitem = {
                id: tests.id,
                bookings: tests.data().bookings,
                bookingType: tests.data().bookingType,
                mobileNo: tests.data().mobileNo,
                branchId: tests.data().branchId,
                slotDate: tests.data().slotDate,
                slotTime: tests.data().slotTime,
                refBy: tests.data().refBy,
                email: tests.data().email,
                address: tests.data().address,
                area: tests.data().area,
                city: tests.data().city,
                pinCode: tests.data().pinCode,
                paymentMode: tests.data().paymentMode,
                remarks: tests.data().remarks,
                phlebotomist: tests.data().phlebotomist,
                phlebotomistId: tests.data().phlebotomistId,
                phlebotomistNumber: tests.data().phlebotomistNumber,
                userId: tests.data().userId,
                createdAt: tests.data().createdAt,
                createdAtOriginal: tests.data().createdAtOriginal,
                status: tests.data().status,
                reportLink: tests.data().reportLink,
                totalPrice: tests.data().totalPrice,
              };

              arrBookings = arrBookings.map((u) =>
                u.id !== newitem.id ? u : newitem
              );

              console.log("exist");
            }
          });
        }

        function objToDate(obj) {
          let result = new Date(0);
          result.setSeconds(obj.seconds);
          result.setMilliseconds(obj.nanoseconds / 1000000);
          return result;
        }

        localStorage.setItem(
          "bookings",
          JSON.stringify(
            arrBookings.sort((a, b) => {
              let bd = objToDate(b.createdAtOriginal);
              let ad = objToDate(a.createdAtOriginal);
              return bd - ad;
            })
          )
        );
        dispatch(
          setBookings(
            arrBookings.sort((a, b) => {
              let bd = objToDate(b.createdAtOriginal);
              let ad = objToDate(a.createdAtOriginal);
              return bd - ad;
            })
          )
        );
      });
    }
  }

  async function handleAddAddress() {
    const userDataCache = localStorage.getItem("userDetails");
    const userDataLocal = {
      addresses: [
        ...JSON.parse(userDataCache).addresses,
        {
          address: address,
          area: area,
          city: city,
          pincode: pincode,
          state: state,
          label: saveAs,
          coordinates: position,
        },
      ],
    };
    updateDoc(doc(db, "Users", userData.phone), userDataLocal);
    localStorage.setItem(
      "userDetails",
      JSON.stringify({ ...JSON.parse(userDataCache), ...userDataLocal })
    );
    dispatch(
      setUserDetails({ ...JSON.parse(userDataCache), ...userDataLocal })
    );
    setAddAddressOpen(false);
    setArea("");
    setAddress("");
    setPincode("");
    setSaveAs("");
    setCity("");
    setState("");
  }

  async function handleAddFamilyMembers() {
    const userDataCache = localStorage.getItem("userDetails");
    const userDataLocal = {
      familyMembers: [
        ...JSON.parse(userDataCache).familyMembers,
        {
          name: memberName,
          gender: memberGender,
          age: age,
          relation: memberRelation,
        },
      ],
    };
    updateDoc(doc(db, "Users", userData.phone), userDataLocal);
    localStorage.setItem(
      "userDetails",
      JSON.stringify({ ...JSON.parse(userDataCache), ...userDataLocal })
    );
    dispatch(
      setUserDetails({ ...JSON.parse(userDataCache), ...userDataLocal })
    );
    setAddMemberOpen(false);
    setMemberGender("");
    setMemberName("");
    setMemberRelation("");
    setAge("");
  }

  return (
    <div className="account_screen">
      <div className="tabs">
        <button
          className={activeTab === "profile" ? "active" : ""}
          onClick={() => handleTabChange("profile")}
        >
          Profile
        </button>
        <button
          className={activeTab === "Addresses" ? "active" : ""}
          onClick={() => handleTabChange("Addresses")}
        >
          Addresses
        </button>
        <button
          className={activeTab === "familyMembers" ? "active" : ""}
          onClick={() => handleTabChange("familyMembers")}
        >
          Family Members
        </button>
        <button
          className={activeTab === "medicalRecords" ? "active" : ""}
          onClick={() => handleTabChange("medicalRecords")}
        >
          Medical Records
        </button>
        <button
          className={activeTab === "appointments" ? "active" : ""}
          onClick={() => handleTabChange("appointments")}
        >
          Appointments
        </button>
        <button
          className={activeTab === "planCard" ? "active" : ""}
          onClick={() => handleTabChange("planCard")}
        >
          Sri Chakra Advantage Plan Card
        </button>
      </div>
      <div className="tab_content">
        {activeTab === "Addresses" && (
          <div className="addresses_div">
            <h2>Saved Addresses</h2>
            <div className="addresses_div_list">
              {userData?.addresses.map((item, index) => {
                return <AddressItem key={index} item={item} />;
              })}
            </div>
            <button
              className="account_save_btn"
              onClick={() => setAddAddressOpen(true)}
            >
              <Add />
              Add Address
            </button>
          </div>
        )}
        {activeTab === "familyMembers" && (
          <div className="addresses_div">
            <h2>Family Members</h2>
            <div className="addresses_div_list">
              {userData?.familyMembers?.map((item, index) => {
                return <MemberItem key={index} item={item} />;
              })}
            </div>
            <button
              className="account_save_btn"
              onClick={() => setAddMemberOpen(true)}
            >
              <Add />
              Add new member
            </button>
          </div>
        )}
        {activeTab === "medicalRecords" && (
          <div className="medical_records">
            <div className="filters">
              <input
                type="text"
                placeholder="Search by Name"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
              {/* <label>
                {"From Date:  "}
                <input
                  type="date"
                  placeholder="From Date"
                  value={fromDate}
                  onChange={(e) => setFromDate(e.target.value)}
                />
              </label>
              <label>
                {"To Date:  "}
                <input
                  type="date"
                  placeholder="To Date"
                  value={toDate}
                  onChange={(e) => setToDate(e.target.value)}
                />
              </label> */}
            </div>
            <h2>Medical Records</h2>
            <div>
              {filteredReports?.length > 0 ? (
                <div className="appointment_list">
                  {filteredReports?.map((booking, index) => (
                    <div key={index} className={`booking_item`}>
                      <div className="booking_item_div1">
                        <h3>
                          Booking ID: <p>{booking?.id}</p>
                        </h3>
                        <p>Status: {booking?.status}</p>
                      </div>
                      <div className="booking_item_div1">
                        <div className="collection_date_time_div">
                          <h3>Collection date & time</h3>
                          <p>{booking?.slotDate}</p>
                          <p>{booking?.slotTime}</p>
                        </div>
                        <div className="collection_date_time_div">
                          <h3>Address</h3>
                          <p>{booking?.address}</p>
                          <p>{booking?.city}</p>
                        </div>
                      </div>
                      {booking?.bookings.map((item, index) => {
                        return (
                          <div key={index} className="booking_item_div2">
                            <p>
                              {item.patientName +
                                " | " +
                                item.age +
                                " | " +
                                item.gender}
                            </p>
                            <div className="booking_item_test_list">
                              {item.bookedTests.map((data, index) => {
                                return (
                                  <div key={index}>
                                    <p>Name: {data.TestName}</p>
                                    <p>Code: {data.TestCode}</p>
                                    <p>Price: {data.Price}</p>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        );
                      })}
                      <div className="reports_btn_grp">
                        <button
                          onClick={() =>
                            window.open(booking?.reportLink, "_blank").focus()
                          }
                          className="assign_btn"
                        >
                          View Report
                        </button>
                        <button
                          onClick={() => navigate("/book-appointment")}
                          className="consult_doc_btn"
                        >
                          Consult Doctor
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <p>No Reports Found!</p>
              )}
            </div>
          </div>
        )}
        {activeTab === "appointments" && (
          <div className="appointments">
            <div className="filters">
              <input
                type="text"
                placeholder="Search by Name"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
              {/* <label>
                {"From Date:  "}
                <input
                  type="date"
                  placeholder="From Date"
                  value={fromDate}
                  onChange={(e) => setFromDate(e.target.value)}
                />
              </label>
              <label>
                {"To Date:  "}
                <input
                  type="date"
                  placeholder="To Date"
                  value={toDate}
                  onChange={(e) => setToDate(e.target.value)}
                />
              </label> */}
            </div>
            <div className="appointment_header">
              <h2>Appointments</h2>
              {/* <Link to={"/appoint"}>
                <button>
                  <AddCircle style={{ marginRight: "0.5rem" }} />
                  New Appointment
                </button>
              </Link> */}
            </div>
            <div>
              {filteredAppointments?.length > 0 ? (
                <div className="appointment_list">
                  {filteredAppointments?.map((booking, index) => (
                    <div key={index} className={`booking_item`}>
                      <div className="booking_item_div1">
                        <h3>
                          Booking ID: <p>{booking?.id}</p>
                        </h3>
                        <p>Status: {booking?.status}</p>
                      </div>
                      {booking?.status === "Phlebotomist Assigned" && (
                        <div className="booking_item_div1">
                          <h3>
                            Phlebotomist: <p>{booking?.phlebotomist}</p>
                          </h3>
                          <a href={`tel:${booking?.phlebotomistNumber}`}>
                            Call
                          </a>
                        </div>
                      )}
                      <div className="booking_item_div1">
                        <div className="collection_date_time_div">
                          <h3>Collection date & time</h3>
                          <p>{booking?.slotDate}</p>
                          <p>{booking?.slotTime}</p>
                        </div>
                        <div className="collection_date_time_div">
                          <h3>Address</h3>
                          <p>{booking?.address}</p>
                          <p>{booking?.city}</p>
                        </div>
                      </div>
                      {booking?.bookings.map((item, index) => {
                        return (
                          <div key={index} className="booking_item_div2">
                            <p>
                              {item.patientName +
                                " | " +
                                item.age +
                                " | " +
                                item.gender}
                            </p>
                            <div className="booking_item_test_list">
                              {item.bookedTests.map((data, index) => {
                                return (
                                  <div key={index}>
                                    <p>Name: {data.TestName}</p>
                                    <p>Code: {data.TestCode}</p>
                                    <p>Price: {data.Price}</p>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  ))}
                </div>
              ) : (
                <p>No Bookings Found!</p>
              )}
            </div>
          </div>
        )}
        {/* Content for other tabs */}
        {activeTab === "planCard" && (
          <div className="plan_card">
            <h2>Sri Chakra Advantage Plan Card</h2>
          </div>
        )}
        {activeTab === "profile" && (
          <div className="profile">
            <div className="profile_head">
              <h2>Edit Profile</h2>
              {/* <button
                onClick={() => {
                  if (window.confirm("Are you sure you want to logout?")) {
                    // Save it!
                    auth.signOut();
                    localStorage.clear();
                    dispatch(setAuth(false));
                    dispatch(setCart([]));
                    dispatch(setTests([]));
                    dispatch(setMedicalRecords([]));
                    dispatch(setBookings([]));
                    navigate("/");
                  } else {
                    // Do nothing!
                  }
                }}
              >
                Logout
              </button> */}
            </div>
            <div className="profile_input_group">
              <label>
                Mobile Number
                <input
                  type="text"
                  placeholder="Mobile Number"
                  value={mobile}
                  readOnly={true}
                  onChange={(e) => setMobile(e.target.value)}
                />
              </label>
            </div>
            <div className="profile_input_group">
              {/* <label>
                Salutation
                <select
                  value={salutation}
                  onChange={(e) => setSalutation(e.target.value)}
                >
                  <option value="">Select Salutation</option>
                  <option value="Mr.">Mr.</option>
                  <option value="Mrs.">Mrs.</option>
                  <option value="Miss.">Miss.</option>
                  <option value="Baby">Baby</option>
                  <option value="Dr.">Dr.</option>
                  <option value="B/O">B/O</option>
                  <option value="Master">Master</option>
                </select>
              </label> */}
              <label>
                Name
                <input
                  type="text"
                  placeholder="Name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </label>
            </div>
            <div className="profile_input_group">
              <label>
                Gender
                <select
                  value={gender}
                  onChange={(e) => setGender(e.target.value)}
                >
                  <option value="">Select Gender</option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                  {/* Add your gender options here */}
                </select>
              </label>
              {/* <input
              type="text"
              placeholder="Age"
              value={age}
              onChange={(e) => setAge(e.target.value)}
            /> */}
              <label>
                Age
                <input
                  type="number"
                  placeholder="Age"
                  value={dateOfBirth}
                  onChange={(e) => setDateOfBirth(e.target.value)}
                />
              </label>
            </div>
            <label>
              Email
              <input
                type="text"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </label>
            <div className="account_address_section">
              {/* <p className="account_address_card_title">Address</p>
              <div className="account_address_list">
                {userData?.addresses?.map((item, index) => {
                  return (
                    <button
                      key={index}
                      className="account_address_item"
                      onClick={() => handleAddressSelect(index)}
                    >
                      <div>
                        <p>{item.address}</p>
                        <p>{item.city + ", " + item.pincode}</p>
                      </div>
                      <div>
                        <input
                          type="radio"
                          value={index}
                          checked={selectedAddress === index}
                          onChange={() => handleAddressSelect(index)}
                        />
                        <p>Edit</p>
                      </div>
                    </button>
                  );
                })}
              </div> */}
              {/* {userData?.addresses?.length < 2 && (
                <button className="add_address_button">
                  <p className="add_address_text">Add Address</p>
                  <div className="add_address_icon">
                    <svg
                      width="25"
                      height="25"
                      viewBox="0 0 25 25"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6.25 12.5H18.75M12.5 18.75V6.25"
                        stroke="white"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                </button>
              )} */}
            </div>
            <button className="account_save_btn" onClick={handleSaveSubmit}>
              Save
            </button>
          </div>
        )}
      </div>
      <AddressSelection
        addAddressOpen={addAddressOpen}
        setAddAddressOpen={setAddAddressOpen}
      />
      <Dialog open={addMemberOpen} onClose={() => setAddMemberOpen(false)}>
        <div className="addresses_div_dialog">
          <h2>Add Member</h2>
          <label>
            Full Name*
            <input
              type="text"
              placeholder="Enter Full Name"
              value={memberName}
              onChange={(e) => setMemberName(e.target.value)}
            />
          </label>
          <div className="profile_input_group">
            <label>
              Gender
              <select
                value={memberGender}
                onChange={(e) => setMemberGender(e.target.value)}
              >
                <option value="">Select Gender</option>
                <option value="Male">Male</option>
                <option value="Female">Female</option>
                {/* Add your gender options here */}
              </select>
            </label>
            <label>
              Age*
              <input
                type="text"
                placeholder="Enter Age"
                value={age}
                onChange={(e) => setAge(e.target.value)}
              />
            </label>
          </div>
          <label>
            Relation*
            <select
              value={memberRelation}
              onChange={(e) => setMemberRelation(e.target.value)}
            >
              <option value="">Select Relation</option>
              <option value="Self">Self</option>
              <option value="Spouse">Spouse</option>
              <option value="Mother">Mother</option>
              <option value="Father">Father</option>
              <option value="Daughter">Daughter</option>
              <option value="Son">Son</option>
              <option value="GrandParents">GrandParents</option>
              <option value="Siblings">Siblings</option>
              <option value="In-Laws">In-Laws</option>
              <option value="Other">Other</option>
            </select>
          </label>
          <button className="account_save_btn" onClick={handleAddFamilyMembers}>
            Add Member
          </button>
        </div>
      </Dialog>
    </div>
  );
};

export default Account;
