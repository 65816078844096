import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { selectCart, setCart } from "../../store";
import "./PlanCart.css";
import { Delete } from "@mui/icons-material";

export default function PlanCart({ item }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const cart = useSelector(selectCart);

  return (
    <div className="card_cart_main">
      <p className="card_cart_main_name">{item.name}</p>
      <div
        style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
      >
        <p className="card_cart_main_price">{"₹" + item.price}</p>
        <div
          onClick={() => {
            localStorage.setItem(
              "cart",
              JSON.stringify(cart.filter((data) => data.code !== item.code))
            );
            dispatch(setCart(cart.filter((data) => data.code !== item.code)));
          }}
        >
          <Delete />
        </div>
      </div>
    </div>
  );
}
