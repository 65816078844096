import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { selectCart, setCart } from "../../store";
import { Dialog } from "@mui/material";
import { ArrowUpward, CloseRounded } from "@mui/icons-material";

function FullDetails({ data, fullDetailsOpen, setFullDetailsOpen }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const cart = useSelector(selectCart);
  const [testIncluded, settestIncluded] = useState(false);

  return (
    <Dialog open={fullDetailsOpen} onClose={() => setFullDetailsOpen(false)}>
      <button
        className="tests_container_close_btn"
        onClick={() => setFullDetailsOpen(false)}
      >
        <CloseRounded />
      </button>
      <div className="test_details_container">
        <h1 style={{ fontWeight: "bold", fontSize: 18, color: "#292929" }}>
          {data.name}
        </h1>
        <div
          style={{
            flexDirection: "row",
            alignItems: "center",
            marginTop: 10,
          }}
        >
          <div
            style={{
              borderWidth: 1,
              borderRadius: 5,
              borderColor: "#eee",
              height: 70,
              width: 70,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <p style={{ color: "#000" }}>{data.type}</p>
          </div>
          <div style={{ marginLeft: 10 }}>
            <p numberOfLines={2} style={{ color: "#292929" }}>
              {data.description}
            </p>
            <p style={{ color: "#002e81", fontWeight: "600", marginTop: 5 }}>
              {data.parameters.length} Test(s) Included
            </p>
          </div>
        </div>
        <div
          style={{
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            marginTop: 20,
          }}
        >
          <div
            style={{
              flexDirection: "row",
              alignItems: "flex-end",
            }}
          >
            <p style={{ color: "#002e81", fontWeight: "bold", fontSize: 22 }}>
              {"₹" + data.price}
            </p>
            <p
              style={{
                color: "#787878",
                fontWeight: "normal",
                fontSize: 14,
                pDecorationLine: "line-through",
                marginBottom: 2,
                marginLeft: 4,
              }}
            >
              {"₹" + data.mrp}
            </p>
          </div>
          <button
            onClick={() => {
              if (cart?.filter((data) => data.id === data.id).length === 0) {
                localStorage.setItem(
                  "cart",
                  JSON.stringify([
                    ...cart,
                    {
                      id: data.id,
                      active: data.active,
                      code: data.code,
                      type: data.type,
                      name: data.name,
                      mrp: data.mrp,
                      price: data.price,
                      deptname: data.deptname,
                      subdeptname: data.subdeptname,
                      gender: data.gender,
                      visitType: data.visitType,
                      fasting: data.fasting,
                      reportsIn: data.reportsIn,
                      description: data.description,
                      parameters: data.parameters,
                      createdAt: data.createdAt,
                      createdAtOriginal: data.createdAtOriginal,
                      testInstructions: data.testInstructions,
                      imgUrl: data.imgUrl,
                    },
                  ])
                );
                dispatch(
                  setCart([
                    ...cart,
                    {
                      id: data.id,
                      active: data.active,
                      code: data.code,
                      type: data.type,
                      name: data.name,
                      mrp: data.mrp,
                      price: data.price,
                      deptname: data.deptname,
                      subdeptname: data.subdeptname,
                      gender: data.gender,
                      visitType: data.visitType,
                      fasting: data.fasting,
                      reportsIn: data.reportsIn,
                      description: data.description,
                      parameters: data.parameters,
                      createdAt: data.createdAt,
                      createdAtOriginal: data.createdAtOriginal,
                      testInstructions: data.testInstructions,
                      imgUrl: data.imgUrl,
                    },
                  ])
                );
              } else {
                localStorage.setItem(
                  "cart",
                  JSON.stringify(cart.filter((data) => data.id !== data.id))
                );
                dispatch(setCart(cart.filter((data) => data.id !== data.id)));
              }
            }}
            style={{
              backgroundColor:
                cart?.filter((data) => data.id === data.id).length === 0
                  ? "#002e81"
                  : "teal",
              borderRadius: 7,
              padding: 12,
              paddingHorizontal: 50,
            }}
          >
            <p style={{ color: "#fff", fontWeight: "600" }}>
              {cart?.filter((data) => data.id === data.id).length === 0
                ? "Add"
                : "Remove"}
            </p>
          </button>
        </div>
        <div
          style={{
            backgroundColor: "#EDF9FF",
            borderRadius: 10,
            padding: 15,
            marginTop: 20,
            gap: 5,
          }}
        >
          <p>
            Reports in{" "}
            <p style={{ fontWeight: "bold", color: "#002e81" }}>
              {data.reportsIn}
            </p>
          </p>
          <p>
            Fasting{" "}
            <p style={{ fontWeight: "bold", color: "#002e81" }}>
              {data.fasting}
            </p>
          </p>
          <p>
            Gender{" "}
            <p style={{ fontWeight: "bold", color: "#002e81" }}>
              {data.gender}
            </p>
          </p>
        </div>
        <div>
          <p
            style={{
              marginTop: 15,
              fontWeight: "bold",
              fontSize: 15,
              color: "#000",
            }}
          >
            Instructions
          </p>
          <button
            style={{
              borderWidth: 1,
              borderColor: "#eee",
              borderRadius: 10,
              paddingHorizontal: 10,
              marginTop: 10,
            }}
          >
            {data.testInstructions.map((item, index) => {
              return (
                <div
                  style={{
                    borderTopWidth: index === 0 ? 0 : 1,
                    borderTopColor: "#eee",
                    paddingVertical: 10,
                  }}
                >
                  <p
                    style={{
                      color: "#000",
                    }}
                    key={index}
                  >
                    {item}
                  </p>
                </div>
              );
            })}
          </button>
        </div>
        <div>
          <p
            style={{
              marginTop: 15,
              fontWeight: "bold",
              fontSize: 15,
              color: "#000",
            }}
          >
            Test(s) Included
          </p>
          <button
            style={{
              borderWidth: 1,
              borderColor: "#eee",
              borderRadius: 10,
              padding: 10,
              marginTop: 10,
            }}
            onClick={() => settestIncluded(!testIncluded)}
          >
            <div
              style={{
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div style={{ width: "92%" }}>
                <p style={{ fontSize: 14, color: "#000", marginBottom: 5 }}>
                  {data.name}
                </p>
                <p style={{ fontSize: 13, color: "gray" }}>
                  {data.parameters.length} Test(s) Included
                </p>
              </div>
              <ArrowUpward />
            </div>
            {testIncluded && (
              <>
                {data.parameters.map((item, index) => {
                  return (
                    <div
                      style={{
                        marginVertical: 5,
                        borderTopWidth: 1,
                        borderTopColor: "#eee",
                        paddingTop: 10,
                      }}
                    >
                      <p
                        style={{
                          color: "#000",
                        }}
                        key={index}
                      >
                        {item}
                      </p>
                    </div>
                  );
                })}
              </>
            )}
          </button>
        </div>
      </div>
    </Dialog>
  );
}

export default FullDetails;
