import "./App.css";
import { Navbar } from "./components/navbar/Navbar";
import Header from "./components/header/Header";
// import { StaticCard } from './StaticCard';
import { Routes } from "react-router-dom";
import { Route } from "react-router-dom";
import Home from "./components/home/Home";
import Upload from "./components/upload/Upload";
import Cart from "./components/cart/Cart";
import Tests from "./components/test/Tests";
import Contact from "./components/contact/Contact";
import Footer from "./components/footer/Footer";
import Login from "./pages/Login/Login";
import Register from "./pages/Register/Register";
import AppointmentScreen from "./pages/AppointmentScreen/AppointmentScreen";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setAuth,
  setCart,
  setTests,
  setUserDetails,
  selectTests,
  selectAuth,
  selectUserDetails,
  setDoctors,
} from "./store";
import Account from "./pages/Account/Account";
import {
  Timestamp,
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  serverTimestamp,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";
import { auth, db } from "./firebase";
import AdvantagePlan from "./pages/AdvantagePlan/AdvantagePlan";
import WhyChooseUs from "./components/WhyChooseUs/WhyChooseUs";
import TermsConditions from "./pages/TermsConditions/TermsConditions";
import PrivacyPolicy from "./pages/PrivacyPolicy/PrivacyPolicy";
import data from "../src/assets/data.json";

function App() {
  const dispatch = useDispatch();
  const authed = useSelector(selectAuth);
  const tests = useSelector(selectTests);

  useEffect(() => {
    // localStorage.clear();

    const cartData = localStorage.getItem("cart");
    if (JSON.parse(cartData) === null || JSON.parse(cartData) === undefined) {
      localStorage.setItem("cart", JSON.stringify([]));
      dispatch(setCart([]));
    } else {
      dispatch(setCart(JSON.parse(cartData)));
    }

    const authData = localStorage.getItem("auth");
    dispatch(setAuth(JSON.parse(authData)));
    const userDetails = localStorage.getItem("userDetails");
    dispatch(setUserDetails(JSON.parse(userDetails)));

    const testsCacheData = localStorage.getItem("testsAndPackages");
    if (JSON.parse(testsCacheData) === null) {
      localStorage.setItem("testsAndPackages", JSON.stringify([]));
    }
    const testsLastDateData = localStorage.getItem("testsLastDate");
    if (testsLastDateData === null) {
      localStorage.setItem(
        "testsLastDate",
        JSON.stringify({ nanoseconds: 0, seconds: 700284600 })
      );
      getTestsAndPackages({ nanoseconds: 0, seconds: 700284600 });
    } else {
      getTestsAndPackages(JSON.parse(testsLastDateData));
    }

    const doctorsCacheData = localStorage.getItem("doctors");
    if (JSON.parse(doctorsCacheData) === null) {
      localStorage.setItem("doctors", JSON.stringify([]));
    } else {
      dispatch(setDoctors(JSON.parse(doctorsCacheData)));
    }

    const doctorsLastDateData = localStorage.getItem("doctorsLastDate");
    if (doctorsLastDateData === null) {
      localStorage.setItem(
        "doctorsLastDate",
        JSON.stringify({ nanoseconds: 0, seconds: 700284600 })
      );
      getdoctors({ nanoseconds: 0, seconds: 700284600 });
    } else {
      getdoctors(JSON.parse(doctorsLastDateData));
    }

    if (authed) {
      getDoc(doc(db, "Users", userDetails.phone)).then((documentSnapshot) => {
        const userData = {
          id: documentSnapshot.id,
          userType: documentSnapshot.data().userType,
          name: documentSnapshot.data().name,
          phone: documentSnapshot.data().phone,
          age: documentSnapshot.data().age,
          gender: documentSnapshot.data().gender,
          email: documentSnapshot.data().email,
          addresses: documentSnapshot.data().addresses,
          familyMembers: documentSnapshot.data().familyMembers,
        };
        localStorage.setItem("userDetails", JSON.stringify(userData));
        dispatch(setUserDetails(userData));
      });
    }
    // uploadData(data);
    // updateDoc(doc(db, "TestsPackages", "SCDDATA"), {
    //   data: data,
    // });
  }, []);

  const uploadData = async (dataArray) => {
    dataArray.forEach((item) => {
      const docRef = doc(db, "TestsPackages", item.code);
      setDoc(docRef, {
        ...item,
        active: true,
        createdAt: serverTimestamp(),
        createdAtOriginal: serverTimestamp(),
      });
    });
  };

  async function getTestsAndPackages(adLastDate) {
    if (adLastDate !== undefined) {
      var startfulldate = Timestamp.fromDate(
        new Date(adLastDate.seconds * 1000 + adLastDate.nanoseconds / 1000000)
      );
      await getDocs(
        query(
          collection(db, "TestsPackages"),
          where("createdAt", ">", startfulldate)
        )
      ).then((documentSnapshot) => {
        var arr = [];
        var arrLastId = [];
        var arrAds = [];
        if (!documentSnapshot.empty) {
          documentSnapshot.forEach((data) => {
            arr.push(data.data().createdAt);
          });
          documentSnapshot.forEach((data) => {
            arrLastId.push(data.data().adId);
          });
          localStorage.setItem(
            "testsLastDate",
            JSON.stringify(arr.sort((a, b) => b - a)[0])
          );
          console.log("avail");
        } else {
          console.log("empty");
        }

        const adsData = localStorage.getItem("testsAndPackages");
        let arrCache = [];
        arrCache.push(JSON.parse(adsData));
        if (arrCache[0].length > 0) {
          arrCache[0].forEach((ad) => {
            arrAds.push(ad);
          });
        }

        if (!documentSnapshot.empty) {
          documentSnapshot.docs.forEach((tests) => {
            if (arrAds.filter((data) => data.id === tests.id).length === 0) {
              console.log("not exist");
              arrAds.push({
                id: tests.id,
                data: tests.data().data,
                createdAt: tests.data().createdAt,
                createdAtOriginal: tests.data().createdAtOriginal,
              });
            } else {
              const newitem = {
                id: tests.id,
                data: tests.data().data,
                createdAt: tests.data().createdAt,
                createdAtOriginal: tests.data().createdAtOriginal,
              };

              arrAds = arrAds.map((u) => (u.id !== newitem.id ? u : newitem));

              console.log("exist");
            }
          });
        }

        console.log(adsData);

        function objToDate(obj) {
          let result = new Date(0);
          result.setSeconds(obj.seconds);
          result.setMilliseconds(obj.nanoseconds / 1000000);
          return result;
        }

        localStorage.setItem("testsAndPackages", JSON.stringify(arrAds));
        dispatch(
          setTests(arrAds[0].data?.filter((data) => data.active === true))
        );
      });
    }
  }

  async function getdoctors(adLastDate) {
    if (adLastDate !== undefined) {
      var startfulldate = Timestamp.fromDate(
        new Date(adLastDate.seconds * 1000 + adLastDate.nanoseconds / 1000000)
      );

      await getDocs(
        query(
          collection(db, "hospitalStaff"),
          where("createdAt", ">", startfulldate),
          where("type", "==", "Doctor")
        )
      ).then(async (documentSnapshot) => {
        var arr = [];
        var arrAds = [];
        if (!documentSnapshot.empty) {
          documentSnapshot.forEach((data) => {
            arr.push(data.data().createdAt);
          });
          localStorage.setItem(
            "doctorsLastDate",
            JSON.stringify(arr.sort((a, b) => b - a)[0])
          );
          console.log("avail");
        } else {
          console.log("empty");
        }

        const adsData = localStorage.getItem("doctors");
        if (adsData) {
          const arrCache = JSON.parse(adsData);
          if (arrCache.length > 0) {
            arrCache.forEach((ad) => {
              arrAds.push(ad);
            });
          }
        }

        if (!documentSnapshot.empty) {
          documentSnapshot.docs.forEach((doctor) => {
            if (arrAds.filter((data) => data.id === doctor.id).length === 0) {
              console.log("not exist");
              arrAds.push({
                id: doctor.id,
                active: doctor.data().active,
                name: doctor.data().name,
                imgUrl: doctor.data().imgUrl,
                charges: doctor.data().charges,
                dateOfBirth: doctor.data().dateOfBirth,
                email: doctor.data().email,
                gender: doctor.data().gender,
                phoneNumber: doctor.data().phoneNumber,
                qualification: doctor.data().qualification,
                specialization: doctor.data().specialization,
                type: doctor.data().type,
                createdAt: doctor.data().createdAt,
                createdAtOriginal: doctor.data().createdAtOriginal,
              });
            } else {
              const newitem = {
                id: doctor.id,
                active: doctor.data().active,
                name: doctor.data().name,
                imgUrl: doctor.data().imgUrl,
                charges: doctor.data().charges,
                dateOfBirth: doctor.data().dateOfBirth,
                email: doctor.data().email,
                gender: doctor.data().gender,
                phoneNumber: doctor.data().phoneNumber,
                qualification: doctor.data().qualification,
                specialization: doctor.data().specialization,
                type: doctor.data().type,
                createdAt: doctor.data().createdAt,
                createdAtOriginal: doctor.data().createdAtOriginal,
              };

              arrAds = arrAds.map((u) => (u.id !== newitem.id ? u : newitem));

              console.log("exist");
            }
          });
        }

        function objToDate(obj) {
          let result = new Date(0);
          result.setSeconds(obj.seconds);
          result.setMilliseconds(obj.nanoseconds / 1000000);
          return result;
        }

        localStorage.setItem(
          "doctors",
          JSON.stringify(
            arrAds
              .sort((a, b) => {
                let bd = objToDate(b.createdAtOriginal);
                let ad = objToDate(a.createdAtOriginal);
                return bd - ad;
              })
              .filter((data) => data.active === true)
          )
        );
        dispatch(
          setDoctors(
            arrAds
              .sort((a, b) => {
                let bd = objToDate(b.createdAtOriginal);
                let ad = objToDate(a.createdAtOriginal);
                return bd - ad;
              })
              .filter((data) => data.active === true)
          )
        );
      });
    }
  }

  return (
    <div className="App">
      <div className="navbar_group">
        <Navbar></Navbar>
        <Header></Header>
      </div>
      {/* <StaticCard></StaticCard> */}
      <Routes>
        <Route path="/" exact element={<Home />}></Route>
        <Route path="/appoint" element={<AppointmentScreen />}></Route>
        <Route path="/upload" element={<Upload />}></Route>
        <Route path="/cart" element={<Cart />}></Route>
        <Route path="/tests" element={<Tests />}></Route>
        <Route path="/contact" element={<Contact />}></Route>
        <Route path="/footer" element={<Footer />}></Route>
        <Route path="/login" element={<Login />}></Route>
        <Route path="/register" element={<Register />}></Route>
        <Route path="/account" element={<Account />}></Route>
        <Route path="/book-appointment" element={<AppointmentScreen />}></Route>
        <Route path="/advantage-plan" element={<AdvantagePlan />}></Route>
        <Route path="/why" element={<WhyChooseUs />}></Route>
        <Route path="/terms-conditions" element={<TermsConditions />}></Route>
        <Route path="/privacy-policy" element={<PrivacyPolicy />}></Route>
      </Routes>
    </div>
  );
}

export default App;
