import React from "react";
import "./WhyChooseUs.css";
import {
  AccessTime,
  AttachMoney,
  Book,
  LocationCity,
  Schedule,
  VerifiedUser,
} from "@mui/icons-material";

const WhyChooseUs = () => (
  <div className="why-choose-us">
    <h2 className="title">Why Choose Us?</h2>
    <div className="features-container">
      <FeatureIcon
        icon={<AccessTime fontSize="large" htmlColor="#002e81" />}
        text="Free Physician Consultation"
      />
      <FeatureIcon
        icon={<Book fontSize="large" htmlColor="#002e81" />}
        text="Free Reports Consultation"
      />
      <FeatureIcon
        icon={<AttachMoney fontSize="large" htmlColor="#002e81" />}
        text="Most Affordable Pricing"
      />
      {/* <FeatureIcon
        icon={<LocationCity fontSize="large" htmlColor="#002e81" />}
        text="Presence in 220+ Cities"
      /> */}
      <FeatureIcon
        icon={<Schedule fontSize="large" htmlColor="#002e81" />}
        text="On-time Sample Collection"
      />
      {/* <FeatureIcon
        icon={<VerifiedUser fontSize="large" htmlColor="#002e81" />}
        text="NABL Certified Labs"
      /> */}
    </div>
  </div>
);

const FeatureIcon = ({ icon, text }) => (
  <div className="feature-icon">
    <span className="material-icons-outlined">{icon}</span>
    <p>{text}</p>
  </div>
);

export default WhyChooseUs;
