import React, { useState } from "react";
import "./AddMember.css";
import { useDispatch, useSelector } from "react-redux";
import { doc, updateDoc } from "firebase/firestore";
import { auth, db } from "../../firebase";
import { selectUserDetails, setUserDetails } from "../../store";
import { Dialog } from "@mui/material";

function AddMember({ setAddMemberOpen, addMemberOpen, data }) {
  const [memberName, setMemberName] = useState(data?.name || "");
  const [memberGender, setMemberGender] = useState(data?.gender || "");
  const [memberRelation, setMemberRelation] = useState(data?.relation || "");
  const [age, setAge] = useState(data?.age || "");
  const dispatch = useDispatch();
  const userDetails = useSelector(selectUserDetails);

  async function handleAddFamilyMembers() {
    const userDataCache = localStorage.getItem("userDetails");
    const userData = {
      familyMembers: [
        ...JSON.parse(userDataCache).familyMembers,
        {
          name: memberName,
          gender: memberGender,
          age: age,
          relation: memberRelation,
        },
      ],
    };
    updateDoc(doc(db, "Users", userDetails.phone), userData);
    localStorage.setItem(
      "userDetails",
      JSON.stringify({ ...JSON.parse(userDataCache), ...userData })
    );
    dispatch(setUserDetails({ ...JSON.parse(userDataCache), ...userData }));
    setAddMemberOpen(false);
    setMemberGender("");
    setMemberName("");
    setMemberRelation("");
    setAge("");
  }

  return (
    <Dialog open={addMemberOpen} onClose={() => setAddMemberOpen(false)}>
      <div className="addresses_div_dialog">
        <h2>Add Member</h2>
        <label>
          Full Name*
          <input
            type="text"
            placeholder="Enter Full Name"
            value={memberName}
            onChange={(e) => setMemberName(e.target.value)}
          />
        </label>
        <div className="profile_input_group">
          <label>
            Gender
            <select
              value={memberGender}
              onChange={(e) => setMemberGender(e.target.value)}
            >
              <option value="">Select Gender</option>
              <option value="Male">Male</option>
              <option value="Female">Female</option>
            </select>
          </label>
          <label>
            Age*
            <input
              type="text"
              placeholder="Enter Age"
              value={age}
              onChange={(e) => setAge(e.target.value)}
            />
          </label>
        </div>
        <label>
          Relation*
          <select
            value={memberRelation}
            onChange={(e) => setMemberRelation(e.target.value)}
          >
            <option value="">Select Relation</option>
            <option value="Self">Self</option>
            <option value="Spouse">Spouse</option>
            <option value="Mother">Mother</option>
            <option value="Father">Father</option>
            <option value="Daughter">Daughter</option>
            <option value="Son">Son</option>
            <option value="GrandParents">GrandParents</option>
            <option value="Siblings">Siblings</option>
            <option value="In-Laws">In-Laws</option>
            <option value="Other">Other</option>
          </select>
        </label>
        <button className="account_save_btn" onClick={handleAddFamilyMembers}>
          Add Member
        </button>
      </div>
    </Dialog>
  );
}

export default AddMember;
