import React, { useState } from "react";
import "./Contact.css";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PhoneIcon from "@mui/icons-material/Phone";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import EmailIcon from "@mui/icons-material/Email";

export default function Contact() {
  return (
    <div className="contact_main">
      <div className="contact_heading">
        <h2 className="contact_heading_h2">Get in touch with us...</h2>
        {/* <hr /> */}
      </div>
      <div className="contact-content">
        <div className="div_address">
          <h2>Address:</h2>
          <div className="contact_address_div">
            <div className="icon">
              <LocationOnIcon />
            </div>
            <div className="contact_address_details">
              <a>Sri Chakra Super Speciality Hospital</a>
              <a>
                Fire Station Road, Kaloji Marg, opp. to Hayagreevachary Ground,
                Hanamkonda, Telangana 506001
              </a>
            </div>
          </div>
          <div className="contact_address_div">
            <div className="icon">
              <PhoneIcon />
            </div>
            <div className="contact_address_details">
              <a href="tel:9494787418">+91 9494787418</a>
              <a href="tel:9441965600">+91 9441965600</a>
            </div>
          </div>
          <div className="contact_address_div">
            <div className="icon">
              <WhatsAppIcon />
            </div>
            <div className="contact_address_details">
              <a
                onClick={() =>
                  (window.location.href =
                    "//api.whatsapp.com/send?phone=919052012320")
                }
              >
                +91 9052012320
              </a>
              <a
                onClick={() =>
                  (window.location.href =
                    "//api.whatsapp.com/send?phone=919494787418")
                }
              >
                +91 9494787418
              </a>
            </div>
          </div>
          <div className="contact_address_div">
            <div className="icon">
              <EmailIcon />
            </div>
            <div className="contact_address_details">
              <a href="mailto:info@srichakrahospitals.com">
                info@srichakrahospitals.com
              </a>
            </div>
          </div>
        </div>
        <div className="div_map">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3794.5354222135297!2d79.56049157506145!3d18.000338782995186!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a3345656b3c49bf%3A0x5c69de50c2134d6d!2sSRI%20CHAKRA%20SUPER%20SPECIALITY%20HOSPITAL!5e0!3m2!1sen!2sin!4v1705026081336!5m2!1sen!2sin"
            width="100%"
            height="100%"
            style={{
              minWidth: "15rem",
              minHeight: "15rem",
              borderRadius: "7px",
            }}
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </div>
    </div>
  );
}
