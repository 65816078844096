import React, { useState } from "react";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import PersonIcon from "@mui/icons-material/Person";
import "./Navbar.css";
import { selectAuth } from "../../store";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

export function Navbar() {
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const authed = useSelector(selectAuth);

  const toggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  const redirect = () => {
    window.location.href = "//api.whatsapp.com/send?phone=919602178333";
  };

  return (
    <nav className={`navbar`}>
      <ul className="navbar_nav">
        <Link className="nav-item active" to={"/"}>
          <li className="nav-link">Home</li>
        </Link>
        <Link
          className="nav-item"
          to={"http://srichakrahospitals.com/aboutus.html"}
        >
          <li className="nav-link">About Us</li>
        </Link>
        <Link to={"/tests"} className="nav-item">
          <li className="nav-link">Tests/Packages</li>
        </Link>
      </ul>
      <ul className="navbar_contact">
        <li className="nav-item">
          <a
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
            className="nav-link"
            href="tel:9052012320"
          >
            <LocalPhoneIcon style={{ marginRight: "5px" }} />
            <span className="link_span">9052012320</span>
          </a>
        </li>
        <li onClick={redirect} className="nav-item">
          <a
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
            className="nav-link"
            href="//api.whatsapp.com/send?phone=919052012320"
          >
            <WhatsAppIcon style={{ marginRight: "5px" }} />
            <span className="link_span">9052012320</span>
          </a>
        </li>
        {/* <Link className="nav-item" to={authed === true ? "/account" : "/login"}>
          <a
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
            className="nav-link"
          >
            <PersonIcon style={{ marginRight: "5px" }} />
            <span className="link_span">
              {authed === true ? "Profile" : "Login"}
            </span>
          </a>
        </Link> */}
      </ul>
    </nav>
  );
}
