import React, { useState } from "react";
import "./Register.css";
import { useDispatch, useSelector } from "react-redux";
import { selectUserDetails, setAuth, setUserDetails } from "../../store";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { useNavigate } from "react-router-dom";
import { doc, updateDoc } from "firebase/firestore";
import { auth, db } from "../../firebase";

function Register({ registerOpen, setRegisterOpen }) {
  const navigate = useNavigate();
  const userDetails = useSelector(selectUserDetails);
  const [mobile, setMobile] = useState(userDetails?.phone);
  const [salutation, setSalutation] = useState("");
  const [name, setName] = useState(userDetails?.name);
  const [gender, setGender] = useState(userDetails?.gender);
  const [dateOfBirth, setDateOfBirth] = useState(userDetails?.age);
  const [email, setEmail] = useState(userDetails?.email);
  const [address, setAddress] = useState("");
  const [area, setArea] = useState("");
  const [city, setCity] = useState("");
  const [pincode, setPincode] = useState("");
  const [state, setState] = useState("");
  const dispatch = useDispatch();

  const handleRegisterSubmit = async () => {
    if (name && dateOfBirth && gender) {
      const userData = {
        name: name,
        age: dateOfBirth,
        gender: gender,
        email: email || "",
      };
      const userDataMember = {
        name: name,
        age: dateOfBirth,
        gender: gender,
        email: email || "",
        familyMembers: [
          {
            name: name,
            gender: gender,
            age: dateOfBirth,
            relation: "Self",
          },
        ],
      };
      updateDoc(
        doc(db, "Users", userDetails.phone),
        userDetails?.familyMembers?.length === 0 ? userDataMember : userData
      );
      const userDataCache = localStorage.getItem("userDetails");
      localStorage.setItem(
        "userDetails",
        JSON.stringify(
          userDetails?.familyMembers?.length === 0
            ? { ...JSON.parse(userDataCache), ...userDataMember }
            : { ...JSON.parse(userDataCache), ...userData }
        )
      );
      dispatch(
        setUserDetails(
          userDetails?.familyMembers?.length === 0
            ? { ...JSON.parse(userDataCache), ...userDataMember }
            : { ...JSON.parse(userDataCache), ...userData }
        )
      );
      setRegisterOpen(false);
      navigate("/");
    } else {
      alert("Please enter all required fields!");
    }
  };

  return (
    <Dialog
      open={registerOpen}
      onBackdropClick={() => setRegisterOpen(true)}
      className="register_main"
    >
      <div className="register_div_card">
        <div className="register_head">
          <h1>Edit Profile.</h1>
          <hr />
        </div>
        {/* <div className="register_input_group">
          <label>
            Mobile Number
            <input
              type="text"
              placeholder="Mobile Number"
              value={mobile}
              readOnly
              onChange={(e) => setMobile(e.target.value)}
            />
          </label>
        </div> */}
        <div className="register_input_group">
          {/* <label>
            Salutation
            <select
              value={salutation}
              onChange={(e) => setSalutation(e.target.value)}
            >
              <option value="">Select Salutation</option>
              <option value="Mr.">Mr.</option>
              <option value="Mrs.">Mrs.</option>
              <option value="Miss.">Miss.</option>
              <option value="Baby">Baby</option>
              <option value="Dr.">Dr.</option>
              <option value="B/O">B/O</option>
              <option value="Master">Master</option>
            </select>
          </label> */}
          <label>
            Name
            <input
              type="text"
              placeholder="Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </label>
        </div>
        <div className="register_input_group">
          <label>
            Gender
            <select value={gender} onChange={(e) => setGender(e.target.value)}>
              <option value="">Select Gender</option>
              <option value="Male">Male</option>
              <option value="Female">Female</option>
              {/* Add your gender options here */}
            </select>
          </label>
          {/* <input
              type="text"
              placeholder="Age"
              value={age}
              onChange={(e) => setAge(e.target.value)}
            /> */}
          <label>
            Age
            <input
              type="number"
              placeholder="Age"
              value={dateOfBirth}
              onChange={(e) => setDateOfBirth(e.target.value)}
            />
          </label>
        </div>
        <label>
          Email (Optional)
          <input
            type="text"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </label>
        {/* <label>
          Address
          <input
            type="text"
            placeholder="Address"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
          />
        </label>
        <div className="register_input_group">
          <label>
            Area
            <input
              type="text"
              placeholder="Area"
              value={area}
              onChange={(e) => setArea(e.target.value)}
            />
          </label>
          <label>
            City
            <input
              type="text"
              placeholder="City"
              value={city}
              onChange={(e) => setCity(e.target.value)}
            />
          </label>
        </div>
        <div className="register_input_group">
          <label>
            Pincode
            <input
              type="text"
              placeholder="Pincode"
              value={pincode}
              onChange={(e) => setPincode(e.target.value)}
            />
          </label>
          <label>
            State
            <input
              type="text"
              placeholder="State"
              value={state}
              onChange={(e) => setState(e.target.value)}
            />
          </label>
        </div> */}
        <button onClick={handleRegisterSubmit}>Save Details</button>
        {/* <p>For any assistance call us on 9052012320</p> */}
      </div>
    </Dialog>
  );
}

export default Register;
