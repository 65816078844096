import React from "react";
import "./Footer.css";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import FacebookIcon from "@mui/icons-material/Facebook";
import IconButton from "@mui/material/IconButton";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import YouTubeIcon from "@mui/icons-material/YouTube";
import TwitterIcon from "@mui/icons-material/Twitter";
import { Link } from "react-router-dom";
import Logo from "../../assets/logo.png";
import { Email, LocationCity, LocationOn, Phone } from "@mui/icons-material";

export default function Footer() {
  return (
    <div className="">
      <div className="footer_div">
        <img src={Logo} />
        <ul className="footer-links">
          <span className="footer_heading">ABOUT</span>
          <Link to={"/"}>
            <li>Home</li>
          </Link>
          <Link to={"/about"}>
            <li>About us</li>
          </Link>
          <Link to={"/tests"}>
            <li>Tests/Packages</li>
          </Link>
          <Link to={"/contact"}>
            <li>Contact Us</li>
          </Link>
          <Link to={"/"}>
            <li>Gallery</li>
          </Link>
        </ul>

        <ul className="footer-links">
          <span className="footer_heading">OTHERS</span>
          <Link to={"/home"}>
            <li>Feedback/Grievance</li>
          </Link>
          <Link to={"/"}>
            <li>Download App</li>
          </Link>
        </ul>

        <div className="connect_with_us">
          <span className="footer_heading">CONNECT WITH US</span>
          <ul className="icon-links">
            <li>
              {" "}
              <IconButton
                href="https://www.facebook.com/profile.php?id=61555849883606"
                target="_blank"
                className="icon-button"
                aria-label="delete"
              >
                <FacebookIcon />
              </IconButton>
            </li>
            <li>
              {" "}
              <IconButton className="icon-button" aria-label="delete">
                <WhatsAppIcon />
              </IconButton>
            </li>
            <li>
              {" "}
              <IconButton
                href="https://www.instagram.com/srichakra_hospital/"
                target="_blank"
                className="icon-button"
                aria-label="delete"
              >
                <InstagramIcon />
              </IconButton>
            </li>
            <li>
              {" "}
              <IconButton
                href="https://twitter.com/srichakra_hosp"
                target="_blank"
                className="icon-button"
                aria-label="delete"
              >
                <TwitterIcon />
              </IconButton>
            </li>
          </ul>
          <div className="connect_links">
            <li>
              <IconButton className="icon-button" aria-label="delete">
                <Email />
              </IconButton>
              <p>Varenyamedicals@gmail.com</p>
            </li>
            <li>
              <IconButton className="icon-button" aria-label="delete">
                <Phone />
              </IconButton>
              <p>+91 9052012320, +91 9494787418</p>
            </li>
            <li>
              <IconButton className="icon-button" aria-label="delete">
                <LocationOn />
              </IconButton>
              <p>
                Fire Station Road, Kaloji Marg, opp. to Hayagreevachary Ground,
                Hanamkonda, Telangana 506001
              </p>
            </li>
          </div>
        </div>
      </div>
      <div className="copyright_section">
        <span>
          Copyright © 2024 Sri Chakra Diagnostic Centre. All Right reserved!
        </span>
        <span>
          <Link style={{ color: "#fff" }} to={"/terms-conditions"}>
            Terms & Conditions
          </Link>
          {" | "}
          <Link style={{ color: "#fff" }} to={"/privacy-policy"}>
            Privacy Policy
          </Link>
        </span>
      </div>
    </div>
  );
}
