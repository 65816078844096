import React, { useEffect, useState } from "react";
import "./Cart.css";
import { useDispatch, useSelector } from "react-redux";
import {
  selectAuth,
  selectCart,
  selectSelectedAddress,
  selectTests,
  selectUserDetails,
  setActiveTab,
  setAuth,
  setCart,
  setLoginDialog,
  setSelectedAddress,
  setUserDetails,
} from "../../store";
import Tests from "../../components/test/Tests";
import PlanCart from "../../components/PlanCart/PlanCart";
import {
  Add,
  ArrowRight,
  CloseRounded,
  Delete,
  Edit,
  LocationOn,
} from "@mui/icons-material";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { useNavigate } from "react-router-dom";
import MemberItem from "../../pages/Account/MemberItem";
import AddressItem from "../../pages/Account/AddressItem";
import {
  addDoc,
  collection,
  doc,
  getDoc,
  serverTimestamp,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { auth, db } from "../../firebase";
import LoadingDialog from "../LoadingDialog";
import AddressSelection from "../AddressSelection/AddressSelection";
import PatientCartSelection from "../PatientCartSelection/PatientCartSelection";
import AddMember from "../AddMember/AddMember";
import Addresses from "../Addresses/Addresses";

const Cart = () => {
  const navigate = useNavigate();
  const cartData = useSelector(selectCart);
  const authed = useSelector(selectAuth);
  const userDetails = useSelector(selectUserDetails);
  const selectedAddress = useSelector(selectSelectedAddress);
  const testsAndPackages = useSelector(selectTests);
  const dispatch = useDispatch();
  const [step, setStep] = useState(1);
  const [appointmentType, setAppointmentType] = useState(
    cartData.filter((data) => data.visitType === "Center").length !== 0
      ? "Center"
      : "Home"
  );
  const [mobileNumber, setMobileNumber] = useState(
    authed === true ? userDetails?.phone : ""
  );
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState(authed === true ? userDetails?.name : "");
  const [saveAs, setSaveAs] = useState("");
  const [testsOpen, setTestsOpen] = useState(false);

  const [gender, setGender] = useState(
    authed === true ? userDetails.gender : ""
  );

  const [email, setEmail] = useState(authed === true ? userDetails.email : "");
  const [address, setAddress] = useState(
    authed === true ? userDetails.addresses[0]?.address : ""
  );
  const [area, setArea] = useState(
    authed === true ? userDetails.addresses[0]?.area : ""
  );
  const [city, setCity] = useState(
    authed === true ? userDetails.addresses[0]?.city : ""
  );
  const [pincode, setPincode] = useState(
    authed === true ? userDetails.addresses[0]?.pincode : ""
  );
  const [state, setState] = useState(
    authed === true ? userDetails.addresses[0]?.state : ""
  );

  const [paymentMode, setPaymentMode] = useState("Online");
  const [remarks, setRemarks] = useState("");
  // userDetails?.addresses[0]
  const [addressOpen, setAddressOpen] = useState(false);

  const [addMemberOpen, setAddMemberOpen] = useState(false);

  const [position, setPosition] = useState({ latitude: null, longitude: null });

  const handleAppointmentTypeChange = (event) => {
    setAppointmentType(event);
  };

  const [selectedDate, setSelectedDate] = useState(new Date().toDateString());
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [availableSlots, setAvailableSlots] = useState([]);
  const [refBy, setRefBy] = useState("");
  const [activePlan, setActivePlan] = useState(null);
  const [total, setTotal] = useState(0);
  const [discount, setDiscount] = useState(0);

  const handleDateChange = (selectedDate) => {
    setSelectedDate(selectedDate);
    setSelectedSlot("");
  };

  const bookSlot = async () => {
    const selectedDateString = new Date(selectedDate).toDateString();

    // Book the slot in Firestore
    await setDoc(
      doc(db, "bookedSlots", selectedDateString),
      {
        [selectedSlot]: true,
      },
      { merge: true }
    );

    // Update UI or navigate to a confirmation page
    console.log("Slot booked successfully!");
  };

  const handleNext = () => {
    if (authed) {
      // Perform validations based on the current step
      if (step === 1) {
        // Check if tests are added to the cart
        if (cartData.length === 0) {
          alert("Please add tests/packages and select a member to proceed.");
          return;
        } else if (selectedPatientsCart.length === 0) {
          alert("Please select a member to proceed.");
          return;
        }
      } else if (step === 2) {
        // Check if an appointment type is selected
        if (!appointmentType || !selectedDate || !selectedSlot) {
          alert("Please select a date & slot for the appointment.");
          return;
        }
        if (!selectedAddress) {
          alert("Please select an address.");
          return;
        }
      } else if (step === 3) {
        // Check if date and slot are selected
        if (!paymentMode) {
          alert("Please select a date and slot for the appointment.");
          return;
        }
      }

      // If all validations pass, proceed to the next step
      setStep(step + 1);
    } else {
      dispatch(setLoginDialog(true));
    }
  };

  const handlePrevious = () => {
    setStep(step - 1);
  };

  const bookAppointment = async () => {
    if (
      !appointmentType ||
      !mobileNumber ||
      !name ||
      !selectedDate ||
      !selectedSlot
    ) {
      alert("Please fill in all required fields.");
      return;
    }

    if (!authed) {
      dispatch(setLoginDialog(true));
      return;
    }

    setLoading(true);
    const bookingId = Math.random().toString().slice(2, 9);

    if (paymentMode === "Online") {
      makePayment(total, bookingId);
    } else {
      bookingFunction(bookingId, total);
    }
  };

  const makePayment = (total, bookingId) => {
    var options = {
      key: "rzp_live_d9ITSWLvosBZTH",
      key_secret: "FyH8Lqj6pRRYx6HKhAlUQoaZ",
      image: "https://i.postimg.cc/dtMjJxkW/logo.png",
      amount: total * 100,
      currency: "INR",
      // order_id: orderId,
      name: "Sri Chakra Diagnostics",
      description: "Sri Chakra Diagnostics",
      handler: async function (response) {
        // alert(response.razorpay_payment_id);
        if (
          response.razorpay_payment_id === "undefined" ||
          response.razorpay_payment_id < 1
        ) {
          alert("Payment Failed!");
        } else {
          try {
            bookingFunction(bookingId, total);
          } catch (error) {
            setLoading(false);
            console.error("Error while booking:", error);
          }
        }
      },
      prefill: {
        email: userDetails.email,
        contact: userDetails.phone,
        name: userDetails.name,
      },
      theme: {
        color: "#002e81",
      },
    };
    var pay = new window.Razorpay(options);
    pay.open();
  };

  async function bookingFunction(bookingId, total) {
    const orders = selectedPatientsCart.map((item) => {
      const patient = userDetails.familyMembers.find(
        (p) => p.name === item.patientId
      );
      const bookedTests = item.cartItems
        .filter((data) => data.selected === true)
        .map((itemId) => {
          return {
            TestCode: itemId.code,
            TestName: itemId.name,
            Price: itemId.price,
          };
        });

      return {
        patientName: patient.name,
        gender: patient.gender,
        age: patient.age,
        bookedTests,
      };
    });

    const combinedOrder = {
      bookings: orders,
      bookingType: appointmentType,
      mobileNo: mobileNumber,
      branchId: "1",
      slotDate: selectedDate,
      slotTime: selectedSlot,
      refBy: refBy,
      email: email,
      address: selectedAddress?.address || "",
      area: selectedAddress?.area || "",
      city: selectedAddress?.city || "",
      pinCode: selectedAddress?.pincode || "",
      paymentMode: paymentMode,
      remarks: remarks,
      phlebotomist: "Not Yet Assigned!",
      phlebotomistId: "",
      phlebotomistNumber: "",
      userId: auth().currentUser.uid,
      createdAt: db.FieldValue.serverTimestamp(),
      createdAtOriginal: db.FieldValue.serverTimestamp(),
      status: "Booked",
      reportLink: "",
      totalPrice: total,
    };

    try {
      await db().collection("Bookings").doc(bookingId).set(combinedOrder);
      bookSlot();
      localStorage.setItem("cart", JSON.stringify([]));
      dispatch(setCart([]));
      alert("Successfully Booked!");
      dispatch(setActiveTab("Profile"));
      navigate("/account");
    } catch (error) {
      console.error("Error while booking:", error);
      alert("Failed to book appointment. Please try again.");
    } finally {
      setLoading(false);
    }
  }

  const fetchAvailableSlots = async (selectedDate, cartData) => {
    const currentDate = new Date();
    const selectedDateObj = new Date(selectedDate);
    const isToday =
      selectedDateObj.toDateString() === currentDate.toDateString();

    const allSlots = [
      "7:00 AM",
      "8:00 AM",
      "9:00 AM",
      "10:00 AM",
      "11:00 AM",
      "12:00 PM",
      "1:00 PM",
      "2:00 PM",
      "3:00 PM",
      "4:00 PM",
      "5:00 PM",
    ];

    const allSlotsFasting = ["7:00 AM", "8:00 AM", "9:00 AM"];
    const allSlotsUSG = ["7:00 PM", "8:00 PM"];

    // try {
    //   // Fetch available slots from Firestore
    //   const snapshot = await db()
    //     .collection('bookedSlots')
    //     .doc(selectedDateObj.toDateString())
    //     .get();

    //   if (!snapshot.exists) {
    //   console.log('No booked slots found for the selected date.');
    setAvailableSlots(
      isToday
        ? getCurrentDayAvailableSlots(
            cartData,
            currentDate,
            allSlots,
            allSlotsFasting,
            allSlotsUSG
          )
        : getAvailableSlots(cartData, allSlots, allSlotsFasting, allSlotsUSG)
    );
    //   return;
    // }

    // const data = snapshot.data();
    // console.log('Fetched data:', data);

    // const bookedSlots = Object.keys(data);
    // const availableSlots = isToday
    //   ? getCurrentDayAvailableSlots(
    //       cartData,
    //       currentDate,
    //       allSlots,
    //       allSlotsFasting,
    //       allSlotsUSG,
    //       bookedSlots,
    //     )
    //   : getAvailableSlots(
    //       cartData,
    //       allSlots,
    //       allSlotsFasting,
    //       allSlotsUSG,
    //       bookedSlots,
    //     );

    // setAvailableSlots(availableSlots);
    // } catch (error) {
    //   console.error('Error fetching available slots:', error);
    // }
  };

  const getCurrentDayAvailableSlots = (
    cartData,
    currentDate,
    allSlots,
    allSlotsFasting,
    allSlotsUSG,
    bookedSlots = []
  ) => {
    const currentHour = currentDate.getHours();
    const currentTimeSlot = `${currentHour % 12 || 12}:00 ${
      currentHour >= 12 ? "PM" : "AM"
    }`;

    const slotsArray =
      cartData.filter((data) => data.fasting !== "Not Required").length !== 0
        ? allSlotsFasting
        : cartData.filter((data) => data.name.includes("USG")).length !== 0
        ? allSlotsUSG
        : allSlots;

    const currentTimeSlotIndex = slotsArray.findIndex(
      (slot) => slot === currentTimeSlot
    );
    return slotsArray
      .slice(currentTimeSlotIndex + 1)
      .filter((slot) => !bookedSlots.includes(slot));
  };

  const getAvailableSlots = (
    cartData,
    allSlots,
    allSlotsFasting,
    allSlotsUSG,
    bookedSlots = []
  ) => {
    const slotsArray =
      cartData.filter((data) => data.fasting !== "Not Required").length !== 0
        ? allSlotsFasting
        : cartData.filter((data) => data.name.includes("USG")).length !== 0
        ? allSlotsUSG
        : allSlots;

    return slotsArray.filter((slot) => !bookedSlots.includes(slot));
  };

  useEffect(() => {
    fetchAvailableSlots(selectedDate, cartData);
  }, [selectedDate, cartData]);

  useEffect(() => {
    setSelectedPatientsCart([]);
  }, [cartData.length]);

  useEffect(() => {
    const authData = localStorage.getItem("auth");
    dispatch(setAuth(JSON.parse(authData)));
    if (authed) {
      const userDetails = localStorage.getItem("userDetails");
      dispatch(setUserDetails(JSON.parse(userDetails)));
      if (!selectedAddress) {
        dispatch(setSelectedAddress(JSON.parse(userDetails).addresses[0]));
      }
      setMobileNumber(JSON.parse(userDetails).phone);
      setName(JSON.parse(userDetails).name);
      setGender(JSON.parse(userDetails).gender);
      setEmail(JSON.parse(userDetails).email);
      if (
        JSON.parse(userDetails).activePlan &&
        new Date(JSON.parse(userDetails).activePlan.validUntil) > new Date()
      ) {
        setActivePlan(JSON.parse(userDetails).activePlan);
        applyDiscount(
          JSON.parse(userDetails).activePlan,
          transformedData
            ?.map((item) => item.totalPrice)
            .reduce((prev, next) => Number(prev) + Number(next))
        );
      }
    }
    if (transformedData?.length > 0) {
      setTotal(
        transformedData
          ?.map((item) => item.totalPrice)
          .reduce((prev, next) => Number(prev) + Number(next))
      );
    }
  }, [authed]);

  const applyDiscount = (plan, total) => {
    let discountAmount = 0;
    if (plan) {
      // Assuming a 10% discount for all plans
      discountAmount = total * 0.15;
    }
    setDiscount(discountAmount.toFixed(0));
    setTotal(total - discountAmount);
  };

  const fixedDateList = generateFixedDateList();

  function generateFixedDateList() {
    const currentDate = new Date();
    const filteredList = [];

    for (
      let i = 0;
      i < (new Date(currentDate).getDate() !== currentDate.getDate() ? 4 : 5);
      i++
    ) {
      const newDate = new Date(currentDate);
      newDate.setDate(newDate.getDate() + i);

      // Check if the date is today and after 5 PM
      if (
        newDate.getDate() !== currentDate.getDate() ||
        newDate.getHours() < 17
      ) {
        filteredList.push(newDate.toDateString());
      }
    }
    return filteredList;
  }

  const [selectedPatientsCart, setSelectedPatientsCart] = useState([]);

  const handleItemSelection = (patientId, itemId) => {
    setSelectedPatientsCart((prevState) => {
      return prevState.map((patient) => {
        if (patient.patientId === patientId) {
          const selectedItemsCount = patient.cartItems.filter(
            (item) => item.selected
          ).length;

          const updatedCartItems = patient.cartItems.map((item) => {
            if (item.id === itemId) {
              // Prevent deselection if this is the only selected item
              if (item.selected && selectedItemsCount === 1) {
                return item;
              }
              return { ...item, selected: !item.selected };
            }
            return item;
          });

          return { ...patient, cartItems: updatedCartItems };
        }
        return patient;
      });
    });
  };

  const removePatient = (patientId) => {
    // setPatients(prevPatients => prevPatients.filter(p => p.name !== patientId));
    setSelectedPatientsCart((prevCart) =>
      prevCart.filter((c) => c.patientId !== patientId)
    );
  };

  const addPatient = (patientId) => {
    const newPatient = {
      patientId: patientId,
      cartItems: cartData.map((item, index) => ({
        ...item,
        selected: index === 0 ? true : false, // Set default selection status
      })),
    };
    setSelectedPatientsCart((prevCart) => [...prevCart, newPatient]);
  };

  const transformData = (patientsCart) => {
    const testMap = {};

    patientsCart.forEach((patient) => {
      patient.cartItems.forEach((item) => {
        if (item.selected) {
          if (!testMap[item.id]) {
            testMap[item.id] = {
              testName: item.name,
              patients: [],
              totalPrice: 0,
              totalMrp: 0,
              ...item,
            };
          }
          testMap[item.id].patients.push(patient.patientId);
          testMap[item.id].totalPrice =
            Number(testMap[item.id].totalPrice) + Number(item.price);
          testMap[item.id].totalMrp =
            Number(testMap[item.id].totalMrp) + Number(item.mrp);
        }
      });
    });

    return Object.values(testMap);
  };

  const transformedData = transformData(selectedPatientsCart);

  return (
    <div className="appointment_container">
      <div className="appointment_main">
        {/* Step 1: Select Appointment Type */}
        {step === 1 && (
          <div className="step">
            <div className="timeline">
              <div onClick={() => setStep(1)} className="timeline-item">
                <div className="dot active">1</div>
              </div>
              <div className="line"></div>
              <div className="timeline-item">
                <div className="dot">2</div>
              </div>
              <div className="line"></div>
              <div className="timeline-item">
                <div className="dot">3</div>
              </div>
            </div>
            <div className="steps_container">
              <div className="tests_list_div">
                <div>
                  <p className="patient_cart-title">Tests/Packages</p>
                  <p className="patient_cart-subtitle">
                    Quick Overview of Tests/Packages
                  </p>
                </div>
                {cartData?.map((item, index) => {
                  return <PlanCart key={index} item={item} />;
                })}
                <button
                  className="add_more_tests"
                  onClick={() => setTestsOpen(true)}
                >
                  <Add />
                  Add More Tests/Packages
                </button>
              </div>
              <div className="patient_cart_selection">
                <div className="patient_cart">
                  <div>
                    <p className="patient_cart-title">
                      {testsAndPackages.filter((data) => data.id === "SCD00002")
                        .length !== 0
                        ? "Select Patient Names"
                        : "Select"}
                    </p>
                    <p className="patient_cart-subtitle">
                      Add needed tests for selected patients
                    </p>
                  </div>
                  <button
                    className="add-member-button"
                    onClick={() => setAddMemberOpen(true)}
                  >
                    <Add color={"#002e81"} size={18} name={"plus"} />
                    <p className="add-member-text">Add Member</p>
                  </button>
                </div>
                <PatientCartSelection
                  patients={userDetails.familyMembers}
                  onItemSelection={handleItemSelection}
                  selectedPatientsCart={selectedPatientsCart}
                  removePatient={removePatient}
                  addPatient={addPatient}
                />
              </div>
            </div>
          </div>
        )}

        {/* Step 2: Slot Booking */}
        {step === 2 && (
          <div className="step">
            <div className="timeline">
              <div onClick={() => setStep(1)} className="timeline-item">
                <div className="dot active">1</div>
              </div>
              <div className="line active"></div>
              <div onClick={() => setStep(2)} className="timeline-item">
                <div className="dot active">2</div>
              </div>
              <div className="line"></div>
              <div className="timeline-item">
                <div className="dot">3</div>
              </div>
            </div>
            <h2>Visit Type</h2>
            <div className="radio_buttons">
              {cartData.filter((data) => data.visitType === "Center").length ===
                0 && (
                <label
                  className={`radio_option ${
                    appointmentType === "Home" ? "selected" : ""
                  }`}
                >
                  <input
                    type="radio"
                    value="Home"
                    checked={appointmentType === "Home"}
                    onChange={handleAppointmentTypeChange}
                  />
                  Home Sample Collection
                </label>
              )}
              <label
                className={`radio_option ${
                  appointmentType === "Center" ? "selected" : ""
                }`}
              >
                <input
                  type="radio"
                  value="Center"
                  checked={appointmentType === "Center"}
                  onChange={handleAppointmentTypeChange}
                />
                Center Visit
              </label>
            </div>
            <div className="slot_booking_container">
              <h2>Sample Collection Date</h2>
              <div className="date_selection">
                {fixedDateList.map((date, index) => (
                  <button
                    className={
                      selectedDate === date ? "date_item active" : "date_item"
                    }
                    key={index}
                    onClick={() => handleDateChange(date)}
                  >
                    <p>
                      {new Date(date).toLocaleString("en-us", {
                        weekday: "short",
                      })}
                    </p>
                    <h4>{new Date(date).getDate()}</h4>
                    <p>
                      {new Date(date).toLocaleString("default", {
                        month: "short",
                      })}
                    </p>
                  </button>
                ))}
              </div>
              <h2>Time</h2>
              <div className="slot_selection">
                {availableSlots?.map((slot, index) => {
                  return (
                    <button
                      onClick={() => setSelectedSlot(slot)}
                      className={
                        selectedSlot === slot ? "slot_item active" : "slot_item"
                      }
                      key={index}
                    >
                      {slot}
                    </button>
                  );
                })}
              </div>
            </div>
            {/* <button className="previous_button" onClick={handlePrevious}>
              Previous
            </button>
            <button className="next_button" onClick={handleNext}>
              Next
            </button> */}
          </div>
        )}

        {/* Step 3: Additional Information */}
        {step === 3 && (
          <div className="step">
            <div className="timeline">
              <div onClick={() => setStep(1)} className="timeline-item">
                <div className="dot active">1</div>
              </div>
              <div className="line active"></div>
              <div onClick={() => setStep(2)} className="timeline-item">
                <div className="dot active">2</div>
              </div>
              <div className="line active"></div>
              <div onClick={() => setStep(3)} className="timeline-item">
                <div className="dot active">3</div>
              </div>
            </div>
            <div className="steps_container">
              <div className="tests_list_div">
                {transformedData.map((item, index) => (
                  <div
                    key={index}
                    style={{
                      borderWidth: "1px",
                      borderColor: "#eee",
                      borderRadius: "5px",
                      padding: "10px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      border: "1px solid #eee",
                    }}
                  >
                    <div style={{ gap: "2px", width: "70%" }}>
                      <p
                        style={{
                          color: "#000",
                          fontSize: "12px",
                          fontWeight: "500",
                          margin: 0,
                        }}
                        title={item.name}
                      >
                        {item.name}
                      </p>
                      <p
                        style={{
                          color: "#000",
                          fontSize: "12px",
                          margin: 0,
                        }}
                      >
                        Reports within {item.reportsIn}
                      </p>
                      <div>
                        <p
                          style={{
                            color: "#000",
                            fontSize: "12px",
                            margin: 0,
                          }}
                        >
                          Booking for:{" "}
                          {item.patients.map((data) => {
                            return `${data}, `;
                          })}
                        </p>
                      </div>
                    </div>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-end",
                          marginRight: "5px",
                        }}
                      >
                        <p
                          style={{
                            color: "#002e81",
                            fontWeight: "bold",
                            fontSize: "15px",
                            margin: 0,
                          }}
                        >
                          {"₹" + item.totalPrice}
                        </p>
                        <p
                          style={{
                            color: "#787878",
                            fontWeight: "normal",
                            fontSize: "12px",
                            textDecoration: "line-through",
                            marginBottom: "2px",
                            marginRight: "4px",
                            margin: 0,
                          }}
                        >
                          {"₹" + item.totalMrp}
                        </p>
                      </div>
                      {/* <div
                        style={{
                          borderWidth: "1px",
                          borderColor: "#eee",
                          padding: "5px",
                          borderRadius: "5px",
                          border: "1px solid #eee",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          const updatedCart = cartData.filter(
                            (data) => data.code !== item.code
                          );
                          localStorage.setItem(
                            "cart",
                            JSON.stringify(updatedCart)
                          );
                          // Assuming setCart is a state update function
                          // setCart(updatedCart);
                        }}
                      >
                        <Delete color="action" />
                      </div> */}
                    </div>
                  </div>
                ))}
              </div>
              <div className="tests_list_div">
                <p style={{ color: "#000", margin: "10px 0" }}>
                  Select Payment Option
                </p>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    gap: "15px",
                  }}
                >
                  <button
                    style={{
                      flex: 1,
                      backgroundColor:
                        paymentMode === "Online" ? "#002e81" : "#eee",
                      borderRadius: "5px",
                      padding: "10px",
                      alignItems: "center",
                      justifyContent: "center",
                      color: paymentMode === "Online" ? "#fff" : "#000",
                      border: "none",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setPaymentMode("Online");
                    }}
                  >
                    Pay Online
                  </button>
                  <button
                    style={{
                      flex: 1,
                      backgroundColor:
                        paymentMode === "While Sample Collection"
                          ? "#002e81"
                          : "#eee",
                      borderRadius: "5px",
                      padding: "10px",
                      alignItems: "center",
                      justifyContent: "center",
                      color:
                        paymentMode === "While Sample Collection"
                          ? "#fff"
                          : "#000",
                      border: "none",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setPaymentMode("While Sample Collection");
                    }}
                  >
                    Pay by Cash
                  </button>
                </div>
                <div style={{ marginTop: "16px" }}>
                  <p
                    style={{
                      fontSize: "16px",
                      fontWeight: "600",
                      color: "#000",
                      margin: "10px 0",
                    }}
                  >
                    Bill Details
                  </p>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginBottom: "10px",
                    }}
                  >
                    <p style={{ color: "#000", margin: 0 }}>
                      {`Total MRP: ${cartData?.length} Items`}
                    </p>
                    <p style={{ color: "#000", margin: 0 }}>
                      ₹
                      {transformedData?.length > 0
                        ? transformedData
                            ?.map((item) => item.totalMrp)
                            .reduce((prev, next) => Number(prev) + Number(next))
                        : "0"}
                    </p>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginBottom: "10px",
                    }}
                  >
                    <p style={{ color: "#000", margin: 0 }}>Discount</p>
                    <p style={{ color: "#00a828", margin: 0 }}>
                      -₹
                      {transformedData?.length > 0
                        ? transformedData
                            ?.map((item) => item.totalMrp)
                            .reduce(
                              (prev, next) => Number(prev) + Number(next)
                            ) -
                          transformedData
                            ?.map((item) => item.totalPrice)
                            .reduce((prev, next) => Number(prev) + Number(next))
                        : "0"}
                    </p>
                  </div>
                  {activePlan && (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginBottom: "10px",
                      }}
                    >
                      <p
                        style={{
                          color: "#01A766",
                          fontWeight: "600",
                        }}
                      >{`Advantage Plan Discount`}</p>
                      <p style={{ color: "#01A766", fontWeight: "600" }}>
                        -₹
                        {discount}
                      </p>
                    </div>
                  )}
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginBottom: "10px",
                    }}
                  >
                    <p style={{ color: "#000", margin: 0 }}>
                      Home Collection Charges
                    </p>
                    <p style={{ color: "#000", margin: 0 }}>FREE</p>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginBottom: "10px",
                    }}
                  >
                    <p
                      style={{
                        color: "#000",
                        fontWeight: "bold",
                        fontSize: "16px",
                        margin: 0,
                      }}
                    >
                      Total Payable Amount
                    </p>
                    <p
                      style={{
                        color: "#000",
                        fontWeight: "bold",
                        fontSize: "16px",
                        margin: 0,
                      }}
                    >
                      ₹ {total}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {/* <div className="bill_card_appoint">
          <p className="bill_card_title">Price Details</p>
          <p className="section_description">
            Your bill will be sent your registered email ID
          </p>
          <div className="bill_amount">
            <p>Total: {cartData?.length} Items</p>
            <p>
              ₹
              {cartData?.length > 0
                ? cartData
                    ?.map((item) => item.price)
                    .reduce((prev, next) => Number(prev) + Number(next))
                : "0"}
            </p>
          </div>
          <div className="bill_amount">
            <p>Home Collection Charges</p>
            <p>Free</p>
          </div>
          <div className="bill_info">
            <p>Amount to be paid:</p>
            <p>
              ₹
              {cartData?.length > 0
                ? cartData
                    ?.map((item) => item.price)
                    .reduce((prev, next) => Number(prev) + Number(next))
                : "0"}
            </p>
          </div>
          {step !== 4 && (
            <button onClick={handleNext} className="proceed_button">
              <p className="proceed_text">Next</p>
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                strokeWidth={1.5}
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5.19727 11.6199L9.0006 7.81655C9.44977 7.36738 9.44977 6.63238 9.0006 6.18322L5.19727 2.37988"
                  stroke="white"
                  stroke-width="1.5"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </button>
          )}
          {step === 4 && (
            <button
              onClick={() => bookAppointment()}
              className="proceed_button"
            >
              <p className="proceed_text">Book Order</p>
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                strokeWidth={1.5}
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5.19727 11.6199L9.0006 7.81655C9.44977 7.36738 9.44977 6.63238 9.0006 6.18322L5.19727 2.37988"
                  stroke="white"
                  stroke-width="1.5"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </button>
          )}
        </div> */}
        {testsOpen === true && (
          <Dialog
            onClose={() => setTestsOpen(false)}
            maxWidth="xl"
            open={testsOpen}
            className="tests_container"
          >
            <button
              className="tests_container_close_btn"
              onClick={() => setTestsOpen(false)}
            >
              <CloseRounded />
            </button>
            <Tests />
          </Dialog>
        )}
      </div>
      <Addresses addressOpen={addressOpen} setAddressOpen={setAddressOpen} />
      <div className="safe-area-view">
        <div className="addresses-view">
          <div className="address-details">
            <LocationOn name="location-pin" color="#000" size={26} />
            {selectedAddress ? (
              <div className="address-text">
                <p className="sample-collection">
                  Sample Collection from:{" "}
                  <span className="address-label">
                    {selectedAddress?.label}
                  </span>
                </p>
                <p className="address-info">
                  {`${selectedAddress?.address}, ${
                    String(selectedAddress?.area).substring(0, 12) + "..."
                  }`}
                  , {selectedAddress?.pincode}
                </p>
              </div>
            ) : (
              <p className="no-address">No Address Selected</p>
            )}
          </div>
          <div className="edit-address" onClick={() => setAddressOpen(true)}>
            <Edit color="#002e81" />
            <span className="edit-address-text">
              {selectedAddress ? "Change" : "Add Address"}
            </span>
          </div>
        </div>
        <div className="footer">
          <div className="patient-count">
            <p className="patient-number">
              {selectedPatientsCart.length < 9 && "0"}
              {selectedPatientsCart.length}
            </p>
            <p className="patient-text">Patients</p>
          </div>
          <button
            onClick={() => {
              if (step === 3) {
                bookAppointment();
              } else {
                handleNext();
              }
            }}
            className="next-button"
          >
            <span className="next-button-text">
              {step === 3 ? "Book Order" : "Next"}
            </span>
            <ArrowRight className="arrow-icon" />
          </button>
        </div>
      </div>
      <AddMember
        addMemberOpen={addMemberOpen}
        setAddMemberOpen={setAddMemberOpen}
      />
      <LoadingDialog open={loading} onClose={() => setLoading(false)} />
    </div>
  );
};

export default Cart;
